import Vue from "vue";
import VueRouter from "vue-router";
import Login_Page from "../components/Authentication/LoginPage.vue";
import Registration from "../components/Authentication/registration.vue";
import DashBoardMain from "../components/DashBoard/Index.vue";
import Dashboard from "../components/DashBoard/DashBoard.vue";
import DocumentsMain from "../components/Documents/Index.vue";
import FoldersMain from "../components/Folders/Index.vue";
import Documents from "../components/Documents/Documents.vue";
import Folders from "../components/Folders/Folders.vue";
import AdminDashboard from "../components/Admin/AdminDashboard.vue"
import AdminLanding from "../components/Admin/index.vue"
import AdminUsers from "../components/Admin/AdminUsers.vue"
import LandingPage from "../components/Landing/index.vue"
import SettingsMain from  "../components/settings/index.vue"
import Settings from  "../components/settings/settings.vue"
import Signiture from "../components/Documents/UpdateSigniture.vue"
import InstitutionMain from "../components/Institutions/index.vue"
import Institutions from "../components/Institutions/institutions.vue"
import ReceivedSharedDocument from "../components/Documents/ReceivedDocument.vue"
import contentdelivery from "../components/Authentication/contentdelivery.vue"
import Profile_Page from "../components/Profile/profile_page.vue"
import Profile from "../components/Profile/index.vue"
import SharedDocument from "../components/Documents/DocumentsShared.vue"
import SharedDocDetails from "../components/Documents/SharedDocDetails.vue"
import UserCheck from "../components/Authentication/CheckUser.vue"
// 
import View from "../components/Documents/Doc.vue";
import SharedView from "../components/Documents/RecievedDocumentView.vue";
import Test from "../components/Documents/test.vue";
import Test2 from '../components/Documents/test2.vue';
// 


import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path:"/",
    name:"Landing Page",
    component:LandingPage,
  },
  {
    path:"/welcome",
    name:"Welcome Page",
    component:contentdelivery,
  },
  {
    path: "/register",
    name: "Registration Page",
    component: Registration,
  },
  {
    path: "/login",
    name: "Login Page",
    component: Login_Page,
  },
  {
    path:"/verify",
    name:"userverification",
    component:UserCheck,
  },
  {
    path: "/profile",
    name: "Profile Page",
    component: Profile,
    children:[
      {
        path: "profile_page",
        name: "Profile Page",
        component: Profile_Page,
      }
    ]
  },
  {
    path:"/landing",
    name:"Landing",
    component:DashBoardMain,
    children:[
       {
        path:"dashboard",
        name:"Dashboard",
        component:Dashboard,
       }
       ,{
        path:"signiture",
        name:"Signiture",
        component:Signiture
      }
    ]
  },

  {
    path: "/documentslanding",
    name: "documents",
    component: DocumentsMain,
    children:[
        {
           path:"documents",
           name:"Documents",
           component:Documents
        },
        {
          path:"signiture",
          name:"Signiture",
          component:Signiture
        },
        {
          path:"documents_recieved",
          name:"documents_recieved",
          component:ReceivedSharedDocument
        },
        {
          path:"documents_shared",
          name:"documents_shared",
          component:SharedDocument
        },
        {
          path:"documents_shared_details",
          name:"documents_shared_details",
          component:SharedDocDetails
        }
    ]
  },
  {
    path: "/folderslanding",
    name: "FoldersLanding",
    component: FoldersMain,
    children:[
      {
        path:"folders",
        name:"Folders",
        component:Folders
      },
      {
        path:"signiture",
        name:"Signiture",
        component:Signiture
      }
    ]
  },
  {
    path: "/viewpdf",
    name: "viewpdf",
    component: View,
  },
  {
    path: "/viewshared",
    name: "viewshared",
    component: SharedView,
  },
  {
    path:"/test",
    name:"test",
    component:Test,
  },
  {
    path:"/test2",
    name:"test2",
    component:Test2,
  },
  // {
  //   path:"/file",
  //   name:"file",
  //   component: () => import('../components/Documents/PDF.html'),
  // },
  {
    path:"/settingslanding",
    name:"SettingsLanding",
    component:SettingsMain,
    children:[
      {
        path:"/settings",
        name:"Settings",
        component:Settings
      },
      {
        path:"signiture",
        name:"Signiture",
        component:Signiture
      }  
    ]
  },
  {
    path:"/Institutions",
    name:"/Institutions",
    component:InstitutionMain,
    children:[
      {
        path:"Institutionlist",
        name:"Institutionlist",
        component:Institutions,
      }
    ]
  },
  {
    path:"/Admin",
    name:"Admin",
    component:AdminLanding,
    children:[
       {
        path:"Admindashboard",
        name:"AdminDashboard",
        component:AdminDashboard,
       },
       {
        path:"users",
        name:"Users",
        component:AdminUsers,
       },
       {
        path:"signiture",
        name:"Signiture",
        component:Signiture,
        },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {

//   let isAuthenticated = store.getters.getTokenState || null;
//   // let canSeeManagerView;
//   // to.meta?.permissions?.includes("staff") ?
//   //   (canSeeManagerView = store.getters.getUserState.isStaff) : (canSeeManagerView = false);
//   store.commit("setLoadingState", true);
//   if (isAuthenticated == null && to.name !== "Login Page") {
//     if (to.name === "SuccessfulActivation") {
//       setTimeout(() => {
//         next();
//       }, 100);
//     } else {
//       next({ name: "Login Page" });
//     }
//   } else if (isAuthenticated !== null) {
//     if (to.name === "Login Page") {
//       next("project/dashboard");
//     } else {
//       if(to.meta?.permissions?.includes("staff")){
//         if(store.getters.getUserState.isStaff){
//           // alert("This is a staff")
//         next()
//         }else{
//           next({ name: "Page not Found" });
//         }
//       }else
//       next()
//     }
//   } else {
//       next();
//   }
// });
router.afterEach(()=>{
  setTimeout(() => {
    store.commit("setLoadingState", false);
  }, 500);
})
export default router;
