<template>
  <v-app>
    <v-main style="background: rgba(12, 46, 76, 0.06)">
      <ModalIdle v-if="isIdle" :isIdle="isIdle" />
      <v-overlay
        :value="$store.getters.getLoadingState"
        opacity="0.9"
        color="#fffffe"
        z-index="9999"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import ModalIdle from "@/components/ModalIdle";
export default {
  components: {
    ModalIdle,
  },
  data() {
    return {
      time: 10,
    };
  },
  computed: {
    isIdle() {
      return (
        this.$store.state.idleVue.isIdle && this.$store.getters.getTokenState
      );
    },
  },
};
</script>
<style lang="css">
main {
  /* background-image: url("./assets/images/background.png"); */
  background-size: cover;
}
.v-application {
  font-family: "Poppins", sans-serif !important;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #eee;
    box-shadow: 0 0 1px 1px #bbb, inset 0 0 7px rgba(0,0,0,0.3)
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 3px;
    border-radius: 10px;
    background: linear-gradient(left, #96A6BF, #63738C);
    box-shadow: inset 0 0 1px 1px #5C6670;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(left, #8391A6, #536175);
  }
</style>
