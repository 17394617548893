import getAllRoles from "../../GraphQL/Admin/Roles/Roles.graphql";
import createUpdateRole from "../../GraphQL/Admin/Roles/createUpdateRole.graphql";
import deleteRole from "../../GraphQL/Admin/Roles/DeleteRoles.graphql";
import { displayErrorSuccessMessages } from "../../components/SharedComponent/Notification/notify";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
  
      getRoles: [],
    },
  
    getters: {
      getRoles: (state) => state.getRoles,
    },
  
    mutations: {
  
    loadRoles(state, data) {
      if (state.getRoles.find(({ uuid }) => uuid === data.uuid)) {
          state.getRoles.find(({ uuid }) => uuid === data.uuid).name = data.name;
          state.getRoles.find(({ uuid }) => uuid === data.uuid).displayName = data.displayName;
          state.getRoles.find(({ uuid }) => uuid === data.uuid).permissions = data.permissions;
      } else {
          state.getRoles.push(data);
      }
    },
    clearRoles(state) {
      state.getRoles = [];
    },
    deleteRoles(state, data) {
      state.getRoles.splice(
        state.getRoles.findIndex(({ uuid }) => uuid === data),
        1
      );
    },

    },
  
    actions: {



      LoadRoles: async (context) => {
        context.commit("setLoadingState", true);
        await apolloClient
          .query({
            fetchPolicy: "network-only",
            query: getAllRoles,
            variables: {
            
            },
          })
          .then((output) => {
            output.data.getAllRoles.content?.forEach((element) => {
              console.log(element.uuid);
              let data={
                id:element.id,
                uuid:element.uuid,
                name:element.name,
                displayName:element.displayName,
                permissions:element.permissions,
              }
              context.commit("loadRoles", data);
            });
          }).finally(()=>{context.commit("setLoadingState", false);})
          .catch((error) => {
            context.commit("setLoadingState", false);
            const data = { 
              error: true,
              message:error 
            }
            displayErrorSuccessMessages(data);
            console.error("getRoles ==>", error);
          });
      },
      
      async createUpdateRole(context,RoleDtoInput){
        apolloClient.mutate({
          mutation:createUpdateRole,
          variables:RoleDtoInput,
        })
        .then((response) => {
          let output =response.data.createUpdateRole;
          console.log(output);
          if(output.code == 5000){
            let tempdata={
              id:output.data.id,
              uuid:output.data.uuid,
              name:output.data.name,
              displayName:output.data.displayName,
              permissions:output.data.permissions,
            }

            context.commit("loadRoles",tempdata);
            context.commit("setLoadingState", false);
            const data = { 
              error: true,
              message:output.message 
            }
            displayErrorSuccessMessages(data);
    
          }
         
          const data = { 
            error: true,
            message:output.message 
          }
          displayErrorSuccessMessages(data);
        })
        .catch((error)=>{
                 console.error(error);
            });
      },
     
      async  DeleteRole(context,uuid){
        apolloClient.mutate({
          mutation:deleteRole,
          variables: {
            roleUuid:uuid
            },
        })
        .then((response) => {
          let output =response.data.deleteRole;
          if(output.code == 5000){
            
            context.commit("deleteRoles", uuid);
            const data = { 
              error: true,
              message:output.message 
            }
            displayErrorSuccessMessages(data);
          }else{
            const data = { 
              error: false,
              message:output.message 
            }
            displayErrorSuccessMessages(data);
          }
         
        })
        .catch((error)=>{
                 console.error(error);
            });
      },
  
  
  }
  
};