<template>
    <div>
        <div class="Toolbar" id="toolbar">
            <v-card
                color="grey-lighten-4"
                flat
                height="70px"
                rounded="0"
            >
                <v-toolbar density="compact">
                    <BackBtn />
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{ getDocDetails().title}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn 
                        icon 
                        @click="prev"
                        color="#0c2e4c"
                    >
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <input part="input" type="text" id="pageSelector" aria-label="Page number">
                    &nbsp;
                    <span id="divider">/</span>
                    &nbsp;
                    <span id="pagelength"></span>
                    <v-btn 
                        icon 
                        @click="next"
                        color="#0c2e4c"
                    >
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                        v-model="dialog"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                class="mx-2"
                                dark
                                color="#0c2e4c"
                            >
                                <v-icon>mdi-draw-pen</v-icon><span>Sign</span>
                            </v-btn>
                        </template>
                        <template>
                            <v-card>
                                <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                                >
                                    <v-container>
                                        <v-text-field
                                            v-model="passphrase"
                                            label="Pass Phrase"
                                            :rules="rules"
                                            hide-details="auto"
                                            type="password"
                                        ></v-text-field>
                                    </v-container>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="red darken-1"
                                            text
                                            @click="closeModal"
                                        >
                                            Close
                                        </v-btn>
                                        <v-btn
                                            color="green darken-1"
                                            text
                                            @click="validate"
                                        >
                                                Submit
                                        </v-btn>
                                            </v-card-actions>
                                        </v-form>
                            </v-card>
                        </template>
                    </v-dialog>
                        <!-- <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                @click="saveFile" 
                                color="#0c2e4c" 
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                              <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                          </template>
                            <span>Save</span>
                        </v-tooltip> -->
                        &nbsp;
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                @click="downloadFile" 
                                color="#0c2e4c" 
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon 
                                >
                                  mdi-tray-arrow-down
                                </v-icon>
                            </v-btn>
                          </template>
                          <span>Download</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                
                </v-toolbar>
            </v-card>
             
        </div>
        <div class="signitureOverlay_div">
            <canvas id="signature_canvas"></canvas>
        </div>
        <div class="document_div">
            <canvas ref="pdfCanvas"></canvas>
        </div>
    </div>
</template>
  
<script>
//   import GetSignature from './GetSignature.vue';
// import router from "../../router";
import BackBtn from "../SharedComponent/Button/BackBtn.vue";
import{PDFDocument}from 'pdf-lib'
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist/legacy/build/pdf';
import { mapGetters } from "vuex";
import { apolloClient } from "@/vue-apollo";
import getSignature from "../../GraphQL/Documents/getSignature.graphql";
//import getDocumentByUuid from "../../GraphQL/Documents/getDocumentByUuid.graphql"
import { displayErrorSuccessMessages } from "../../components/SharedComponent/Notification/notify.js";
import router from "../../router";

  export default {
    components:{
        // GetSignature,
        BackBtn
    },
    data(){
      return{
        canvas:document.getElementById('signature_canvas'),
        dialog: false,
        passphrase:'',
        rules: [
            value => !!value || 'Required.',
            (value) => value && value.length >= 8 || 'Passphrase must be at least 8 characters',
            (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) || 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number',
        ],
        move:false,
        sig_pic: {
          x:0,
          y:0, 
          iWidth:100,
          iHeight:40
        },
        image:new Image(),
        isDown:false,
        pi2:Math.PI*2,
        resizerRadius:8,
        rr:this.resizerRadius*this.resizerRadius,
        draggingResizer:{
          x:0,
          y:0
        },
        imageX:0,
        imageY:0,
        draggingImage:false,
        imageHeight:0,
        imageWidth:0,
        imageRight:0,
        imageBottom:0,
        animationId:"",
        pageNum:1,
        pdfwidth:0,
        pdfheight:0,
        pathFile:"",
      }
      
    },
    mounted() {
     this.initializePdf(this.pageNum); 
    //   this.getFile()
      
    },
    computed:{
      ...mapGetters(['getSignature']),

      signature(){
        return this.getSignature
      }

    },
    methods:{

       saveFile(){
          router.push("/documentslanding/documents_recieved");
          const data = { 
              error: true,
              message:"File Saved" 
          }
          displayErrorSuccessMessages(data);
        },

        async downloadFile(){
          try {
              const pdfFile = this.pathFile;
              const response = await fetch(pdfFile);
              const pdfBlob = await response.blob();

              // Create a temporary URL for the Blob
              const pdfURL = URL.createObjectURL(pdfBlob);

              // Create a link element for downloading the PDF
              const link = document.createElement('a');
              link.href = pdfURL;
              link.download = 'modified.pdf'; // Set the desired filename for the downloaded file

              // Programmatically click the link to trigger the download
              link.click();

              // Clean up by revoking the temporary URL
              URL.revokeObjectURL(pdfURL);
           
            } catch (error) {
              console.error(error);
            }
        },



        closeModal() {
            this.dialog = false;
        },

        async validate () {
            const valid=this.$refs.form.validate();
            if(valid){
                this.dialog=false;
                apolloClient.mutate({
                    mutation:getSignature,
                    variables:{passphrase:this.passphrase},
                })
                .then((response) => {
                    let output =response.data.getSignature;
                    console.log(output);
                    this.sig_pic.x=this.pdfwidth/2;
                    this.sig_pic.y=this.pdfheight/2;
                    if(output.code == 5000){
                    this.addSignature(output.data)
                    localStorage.setItem("setSig",output.data)
                    const data = { 
                        error: true,
                        message:output.message 
                    }
                    displayErrorSuccessMessages(data);
                    }
                    else {
                    const data = { 
                        error: false,
                        message:output.message,
                        code:output.code 
                    }
                    displayErrorSuccessMessages(data);
                    }
                })



            }
        },

        getFile(){
        //     console.log(this.$store.state.docuuid);
        //   apolloClient.mutate({
        //     mutation:getDocumentByUuid,
        //     variables:{uuid: this.$store.state.docuuid},
        //   })
        //   .then((response) => {
        //     let output =response.data.getDocumentByUuid;
        //     console.log(output);
        //     if(output.code == 5000){
        //       this.pathFile=output.data.filePath,
        //      this.initializePdf(this.pageNum,output.data.filePath)
        //       // const data = { 
        //       //   error: true,
        //       //   message:output.message 
        //       // }
        //       // displayErrorSuccessMessages(data);
        //     }
        //     else {
        //       const data = { 
        //         error: false,
        //         message:output.message,
        //         code:output.code 
        //       }
        //         displayErrorSuccessMessages(data);
        //     }
        //   })
        this.initializePdf(this.pageNum,this.$store.state.filePath)
        },



      addSignature(file){
            const canvas = document.getElementById('signature_canvas');
            const ctx = canvas.getContext('2d');
            const w =  canvas.width = this.pdfwidth;
            const h =  canvas.height  = this.pdfheight;
            canvas.addEventListener('mousemove', this.handleMouseMove);
            canvas.addEventListener('mousedown', this.handleMouseDown);
            canvas.addEventListener('mouseup',this.handleMouseUp);
        
            // this.image.src = this.getSignaturefromLocalStorage();
            this.image.src = file;
            const animate = () => {


              
                ctx.clearRect(0, 0, w, h)
                ctx.drawImage(this.image, this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth, this.sig_pic.iHeight)
        
        
                let imageX=this.sig_pic.x;
                let imageY=this.sig_pic.y;
                let imageRight=imageX+this.sig_pic.iWidth;
                let imageBottom=imageY+this.sig_pic.iHeight;
        
            // optionally draw the connecting anchor lines
            
            ctx.beginPath();
            ctx.moveTo(imageX, imageY);
            ctx.lineTo(imageRight, imageY);
            ctx.lineTo(imageRight, imageBottom);
            ctx.lineTo(imageX, imageBottom);
            ctx.closePath();
            ctx.strokeStyle = '#0c2e4c';
            ctx.stroke();
            //draw anchors
                this.drawDragAnchor(imageX, imageY);
                this.drawDragAnchorClose(imageRight, imageY);
                this.drawDragAnchorResize(imageRight, imageBottom);
                this.drawDragAnchorTick(imageX, imageBottom);

                this.animationId = window.requestAnimationFrame(animate);
            }
            animate()
      },
  
      initializePdf(pageNum){
        // const pNo=1;
        // console.log(filePath);
        this.pathFile=this.$store.state.filepath;
        console.log(this.$store.state.filepath);
        this.pageNum=pageNum;
        const canvas = this.$refs.pdfCanvas;
        const pdfUrl = this.$store.state.filepath; // Replace with the path or URL to your PDF file
        GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.7.107/pdf.worker.js'; // Path to the pdf.worker.js file
  
        getDocument(pdfUrl).promise.then((pdf) => {
            document.getElementById('pagelength').innerHTML = pdf.numPages;
          pdf.getPage(pageNum).then((page) => {
            const K =page._pageInfo.view[3];
            console.log(K);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            this.pdfheight= page._pageInfo.view[3];
            // this.pdfheight= canvas.height;
            this.pdfwidth=viewport.width;

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
  
            page.render(renderContext);
            document.getElementById('pageSelector').value =pageNum;
          });
        });
      },
  
      printSignature(){
        const canvas = this.$refs.pdfCanvas;     
        const pdfUrl = this.pathFile;
        // Load the PDF document
        getDocument(pdfUrl).promise.then(pdf => {
          // Get the first page of the PDF
          pdf.getPage(this.pageNum).then(page => {
            if(!page){
              console.log("error")
            }
            // const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
  
            // Load the image
            const image = new Image();
            image.src = this.getSignaturefromLocalStorage();
            image.onload = () => {
             
              ctx.drawImage(image, this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth, this.sig_pic.iHeight);
              this.saveSignature();
            };
          });
        });
        // this.saveSignature();
      },

      

      async saveSignature() {

          try {
              const pdfFile = this.pathFile;
              const response = await fetch(pdfFile);
              const pdfBlob = await response.blob();

              const pdfDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());
              const pngImageBytes = await fetch( this.getSignaturefromLocalStorage()).then((res) =>
                res.arrayBuffer()
              );

              const pngImage = await pdfDoc.embedPng(pngImageBytes);
              const page = pdfDoc.getPages()[this.pageNum-1];

              // const imageSize = pngImage.scale(0.5);
            
              page.drawImage(pngImage, {
                x: this.sig_pic.x,
                y: 750-this.sig_pic.y,
                width: this.sig_pic.iWidth,
                height: this.sig_pic.iHeight,
              });

              const pdfBytes = await pdfDoc.save();
              //console.log(this.toBase64(pdfBytes));
              // Convert PDF bytes to Base64
              // const base64String =Buffer.toString(
              //   String.fromCharCode.apply(null, new Uint8Array(pdfBytes))
              // );
              // console.log(base64String);

              const base64String = this.toBase64(pdfBytes);
              const documentDto ={
                uuid:this.$store.state.docuuid,
                extension:".pdf",
                base64Content:base64String,
                // description:"testing",
                // title:"return document",
              }
              
              this.$store.dispatch('saveSharedSignedDocuments', { documentDto });

            //   this.pdfBase64 = base64String;
           return;
            } catch (error) {
              console.error(error);
            }
      },

      toBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );


        // const binary = new Uint8Array(uint8Array);
        // const bin=String.fromCharCode.apply(null, new Uint8Array(binary))
        // const base64 = btoa(bin);
        // return base64;
      },
     
  
      handleMouseMove(event) {
  
        let imageX=this.sig_pic.x;
        let imageY=this.sig_pic.y;
        let imageRight=imageX+this.sig_pic.iWidth;
        let imageBottom=imageY+this.sig_pic.iHeight;
  
  
        if (this.draggingResizer > -1) {
          
          const canvas=document.getElementById('signature_canvas');
          const topdiv=document.getElementById('toolbar');
         
              var offsetX = canvas.offsetLeft;
              var offsetY = canvas.offsetTop;
              let mouseX = parseInt(event.clientX - offsetX);
              let mouseY = parseInt(event.clientY - offsetY-topdiv.offsetHeight);
  
              console.log("boolean",this.draggingResizer);
              // resize the image
              switch (this.draggingResizer) {
                  case 0:{
                     //top-left
                      imageX = mouseX;
                      this.imageWidth = imageRight - mouseX;
                      imageY = mouseY;
                      this.imageHeight = imageBottom - mouseY;
                      break;
                  }
                     
                  case 1:
                      //top-right
                      imageY = mouseY;
                     this.imageWidth = mouseX - imageX;
                     this.imageHeight = imageBottom - mouseY;
                      break;
                  case 2:
                      //bottom-right
                      this.sig_pic.iWidth = mouseX - imageX;
                      this.sig_pic.iHeight = mouseY - imageY;
                      break;
                  case 3:
                      //bottom-left
                      imageX = mouseX;
                      this.imageWidth = imageRight - mouseX;
                      this.imageHeight = mouseY - imageY;
                      break;
              }
  
              if(this.imageWidth<25){this.imageWidth=25;}
              if(this.imageHeight<25){this.imageHeight=25;}
  
              // set the image right and bottom
              this.imageRight = imageX + this.imageWidth;
              this.imageBottom = imageY + this.imageHeight;
  
              // redraw the image with resizing anchors
              // draw(true, true);
  
              } else if (this.draggingImage) {
  
             
                      let canvas=document.getElementById('signature_canvas');
                      const canvasCoords = canvas.getBoundingClientRect();
                    if(this.move){
                        this.sig_pic.x = event.pageX - canvasCoords.left;
                        this.sig_pic.y = event.pageY - canvasCoords.top;
                    }
              }
  
  
  
       
        
        
      },
  
      getMouseCoords(event){
        let canvas=document.getElementById('signature_canvas');
        let canvasCoords = canvas.getBoundingClientRect()
        return {
            x: event.pageX - canvasCoords.left,
            y: event.pageY - canvasCoords.top
        }
      },
  
      cursorInRect(mouseX, mouseY, rectX, rectY, rectW, rectH){
        let xLine = mouseX > rectX && mouseX < rectX + rectW
        let yLine = mouseY > rectY && mouseY < rectY + rectH
  
        return xLine && yLine
        },
  
      handleMouseDown(event) {
        let canvas=document.getElementById('signature_canvas');
        console.log("mousedown");
        const topdiv=document.getElementById('toolbar');
         
        
        let canvasCoords = canvas.getBoundingClientRect();
            let x= event.pageX - canvasCoords.left;
            let y= event.pageY - canvasCoords.top;
      
        console.log(x, y, this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth, this.sig_pic.iHeight);
        if (this.cursorInRect(x,y, this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth, this.sig_pic.iHeight)) {
          this.move=true;
        } else {
           this.move=false;
        }
        
        var offsetX = canvas.offsetLeft;
        var offsetY = canvas.offsetTop;
        this.startX = parseInt(event.clientX - offsetX-3);
        this.startY = parseInt(event.clientY - offsetY-topdiv.offsetHeight-3);
        
        
        this.draggingResizer = this.anchorHitTest(this.startX, this.startY);
        this.draggingImage = this.draggingResizer < 0 && this.cursorInRect(x,y, this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth, this.sig_pic.iHeight);
      },
  
      handleMouseUp() {
        this.draggingResizer = -1;
        this.draggingImage = false;
        // draw(true, false);
      },
  
      drawDragAnchorClose(x, y) {
        const canvas=document.getElementById('signature_canvas');
        const ctx=canvas.getContext('2d');
        const size = 4; // Adjust the size of the X symbol as needed
        ctx.beginPath();
        ctx.arc(x, y, this.resizerRadius, 0, this.pi2, false);

        ctx.fillStyle = 'white'; // Set the fill color to white
        ctx.fill();

        ctx.moveTo(x - size, y - size);
        ctx.lineTo(x + size, y + size);
        ctx.moveTo(x + size, y - size);
        ctx.lineTo(x - size, y + size);
        ctx.lineWidth = 2; // Adjust the line width as needed
        ctx.strokeStyle = 'red'; // Adjust the color as needed
        ctx.stroke();

       
      },

      drawDragAnchorTick(x, y) {
        const canvas=document.getElementById('signature_canvas');
        const ctx=canvas.getContext('2d');
        const size = 4; // Adjust the size of the tick symbol as needed

        ctx.beginPath();
        ctx.arc(x, y, this.resizerRadius, 0, this.pi2, false);
        ctx.fillStyle = 'white'; // Set the fill color to white
        ctx.fill();
        ctx.moveTo(x - size, y);
        ctx.lineTo(x, y + size);
        ctx.lineTo(x + size, y - size);
        ctx.lineWidth = 2; // Adjust the line width as needed
        ctx.strokeStyle = 'green'; // Adjust the color as needed
        ctx.stroke();
      },

      drawDragAnchorResize(x, y) {
        const canvas = document.getElementById('signature_canvas');
        const ctx = canvas.getContext('2d');
        const size = 3; // Adjust the size of the arrow symbols as needed

        

          // Draw the forward arrow at 270 degrees
        ctx.beginPath();
        ctx.arc(x, y, this.resizerRadius, 0, this.pi2, false);
        ctx.moveTo(x, y - size);
        ctx.lineTo(x - size, y + size);
        ctx.lineTo(x + size, y + size);
        ctx.closePath();
        ctx.strokeStyle = 'blue'; // Adjust the color as needed
        ctx.stroke();
        ctx.fill();
      },

      drawDragAnchor(x, y) {
        const canvas=document.getElementById('signature_canvas');
        const ctx=canvas.getContext('2d');
        ctx.beginPath();
        ctx.arc(x, y, this.resizerRadius, 0, this.pi2, false);
        ctx.closePath();
        ctx.fillStyle = 'gray';
        ctx.fill();
      },
  
      anchorHitTest(x, y) {
        // let canvas=document.getElementById('signature_canvas');
        let imageRight=this.sig_pic.x+this.sig_pic.iWidth;
        let imageBottom=this.sig_pic.y+this.sig_pic.iHeight;
        let imageX=this.sig_pic.x;
        let imageY=this.sig_pic.y;
  
  
        var dx, dy;
  
        // top-left
        dx = x - this.sig_pic.x;
        dy = y - this.sig_pic.y;
        if (dx * dx + dy * dy <= 64) {
            return (0);
        }
        // top-right
        dx = x - imageRight;
        dy = y - imageY;
        if (dx * dx + dy * dy <= 64) {
          window.cancelAnimationFrame(this.animationId);
            const canvas=document.getElementById('signature_canvas');
            const ctx = canvas.getContext('2d');
            const w =  canvas.width = window.innerWidth * 0.9/2.4;
            const h =  canvas.height  = window.innerHeight * 0.9/1.1;
            ctx.clearRect(0, 0, w, h);
            //ctx.clearRect(this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth,this.sig_pic.iHeight);
            return (1);
        }
        // bottom-right
        dx = x - imageRight;
        dy = y - imageBottom;
        if (dx * dx + dy * dy <= 64) {
            return (2);
        }
        // bottom-left
        dx = x - imageX;
        dy = y - imageBottom;
        if (dx * dx + dy * dy <= 64) {
         this.printSignature();
            return (3);
        }
        return (-1);
  
      },
  
    getSignaturefromLocalStorage(){
      return localStorage.getItem("setSig")
    },
    
    getDocDetails(){
      const title =this.$store.state.doctitle;
      const uuid  =this.$store.state.docuuid;
      const description=this.$store.state.docdescription;
      const detailes={
        title:title,
        uuid:uuid,
        description:description,
      }
      return detailes;
    },

    next() {
      this.pageNum++;
      // this.initializePdf(this.pageNum);
      this.getFile();
      // render(pageNum);
    },   

    prev() {
        if(this.pageNum==1){
        console.log('');
        }
        else{
            this.pageNum--;
            // this.initializePdf(this.pageNum);
            this.getFile();
            // render(pageNum);
        }
    }
      
    }

  

    
  };
  </script>

  <style>
  .document_div{
    /* background-color: aquamarine; */
    text-align: center;
    justify-content: center;
    margin:0 auto;
    width: 90%;
    padding: 0;
    z-index: 1;
  }
  
  .signitureOverlay_div{
    /* background-color: aqua; */
    position: absolute;
    text-align: center;
    z-index: 2;
    height: 80vh;
    width: 100%;
  }
  
  #signature_canvas{
    display: block;
    box-sizing: border-box;
    /* border: 1px solid #0c2e4c; */
    border: none;
    margin: 0 auto;
   
  }
  
  /* .toolbar_div{
    background-color: gainsboro;
    text-align: center;
  } */
  
  input{
    width: 30px;   
  }
  
  .Toolbar{
    /* background-image:linear-gradient(165deg,#0c2e4c,rgb(0, 100, 187)); */
     /* text-align: center; */
    /* padding-top: 10px;
    height: 50px;
    border: 5px;
    border-radius: 5px;
    border-bottom: #0c2e4c; */
  
  }
  
  .btn_prev{
    /* justify-content: end; */
    text-align: right;
  }
  
  .btn_AddSave{
    text-align: right;
  }
  
  </style>