<template>
  <v-app>
    <v-container fluid>
     
      <div class="row ml-5 mt-5">
        <BackBtn />
      </div>
      &nbsp;
          <v-row dense>
            <v-col>
              <h2>
                Dashboard
              </h2>
            </v-col>
          </v-row>
            <!-- <v-text><p>Total Documents | {{ userfile.totalDocument }}</p></v-text> -->
            <hr>
            &nbsp;
        <v-layout row wrap>
          <v-flex xs12 sm6 md3 lg3>
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card class="text-xs-center ma-3" max-width="344" :elevation="hover ? 16 : 2"
                :class="{ 'on-hover': hover }" >
                <v-card-title>Total Documents</v-card-title>
                <v-card-text><span>
                    <center>
                      <v-chip color="#3cd1c2">{{
                       userfile.totalDocument
                      }}</v-chip>
                    </center>
                  </span></v-card-text>
                <v-icon small color="#3cd1c2">mdi-comment</v-icon>
              </v-card>
            </v-hover>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3>
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card raised class="text-xs-center ma-3" max-width="344" :elevation="hover ? 16 : 2"
                :class="{ 'on-hover': hover }" link to="/documentslanding/documents_shared">
                <v-card-title>Shared Documents</v-card-title>
                <v-card-text><span>
                    <center>
                      <v-chip color="#f83e70">{{
                        userfile.totalSharedDocument
                      }}</v-chip>
                    </center>
                  </span></v-card-text>
                <v-icon small color="#f83e70">mdi-cached</v-icon>
              </v-card>
            </v-hover>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3>
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card class="text-xs-center ma-3" max-width="344" :elevation="hover ? 16 : 2"
                :class="{ 'on-hover': hover }" @click="recieved('tab1')">
                <v-card-title>UnSigned Documents</v-card-title>
                <v-card-text><span>
                    <center><v-chip color="#ffaa2c">{{
                       userfile.totalUnsignedDocument
                      }}</v-chip></center>
                  </span></v-card-text>
                <v-icon small color="#ffaa2c">mdi-comment-processing</v-icon>
              </v-card>
            </v-hover>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3>
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card class="text-xs-center ma-3" max-width="344" :elevation="hover ? 16 : 2"
                :class="{ 'on-hover': hover }" @click="recieved('tab2')">
                <v-card-title>Signed Documents</v-card-title>
                <v-card-text><span>
                    <center>
                      <v-chip color="#43A047">{{
                       userfile.totalSignedDocument
                      }}</v-chip>
                    </center>
                  </span></v-card-text>
                <v-icon small color="#43A047">mdi-comment-check</v-icon>
              </v-card>
            </v-hover>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3>
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card class="text-xs-center ma-3" max-width="344" :elevation="hover ? 16 : 2"
                :class="{ 'on-hover': hover }" link to="/documentslanding/documents">
                <v-card-title>My Documents</v-card-title>
                <v-card-text><span>
                    <center>
                      <v-chip color="primary">{{
                       userfile.myDocument
                      }}</v-chip>
                    </center>
                  </span></v-card-text>
                <v-icon small color="primary">mdi-comment</v-icon>
              </v-card>
            </v-hover>
          </v-flex>
        </v-layout>
        <br /><br />
        <v-divider> </v-divider>
        <br />
      
    </v-container>
  </v-app>
  
</template>
  
<script>
// import VueApexCharts from "vue-apexcharts";
import router from "../../router";
import BackBtn from "../SharedComponent/Button/BackBtn.vue";
import { mapGetters } from 'vuex';

export default {

  components: {
    // apexcharts: VueApexCharts, 
    BackBtn 
    
  },
  data: function () {
    return {
      dialog:true,
      countCompletedStaffAppointments: null,
      chartOptions: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: [
            "Completed",
            "Ongoing",
            "Delayed",
            "On Progress",
            "On Production",
          ],
        },
      },
      series: [
        {
          name: "Completed",
          data: [10, 50, 49, 60, 91],
        },
      ],
      series1: [21, 17, 15, 10, 30],
      chartOptions1: {
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        fill: {
          opacity: 0.8,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },

  computed:{
    ...mapGetters(['getDashboard']),
    userfile()
      {
        return this.getDashboard
      },
  },
  mounted(){
    this.$store.dispatch('getDashboard');
  },

  methods:{
    recieved(tab){
      localStorage.setItem("tab",tab);
      router.push("/documentslanding/documents_recieved");
    }
  },

  
};
</script>
  
<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>