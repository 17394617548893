<template>
  <div>
    <!-- Nav -->
      <div class="nav">
        <div class="row">
          <div class="col-md-4 col-lg-2">
            <img src="../../../public/logo.png" alt="Logo" width="100px">
          </div>
          <div class="col-md-8 col-lg-10">
            <v-col class="text-right">
              <router-link to="/register">
                <v-btn
                  right
                  color="#00529a"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Register
                </v-btn>
              </router-link>
              &nbsp;
              <router-link to="/login">
                <v-btn
                  right
                  color="#00529a"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Login
                </v-btn>
              </router-link>
            </v-col>                   
          </div>
        </div> 
      </div>
    <!-- / end nav -->
    <!-- First Section -->
    <div class="header">
    

      <div class="row justify-end ">
        <div class="col-md-12 col-lg-6">
          <div class="text-center centered-content animate">
              <img src="../../../public/esignature_logo.png" width="400px" alt="e-Signature">         
              <p>Welcome to e-Signature - the ultimate solution for creating and adding digital signatures to your files.<br>
                  Our app is designed to make the process of signing documents easy and hassle-free, whether you're in the office or on-the-go.</p>
          </div>
        </div>
      </div>
      <!-- waves -->
                <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g class="parallax">
                <use xlink:href="#gentle-wave" x="48" y="0" fill="RGB(0, 82, 154)" />
                <use xlink:href="#gentle-wave" x="48" y="3" fill="RGBA(0, 82, 154, 0.615)" />
                <use xlink:href="#gentle-wave" x="48" y="5" fill="RGBA(0, 82, 154, 0.415)" />
                <use xlink:href="#gentle-wave" x="48" y="7" fill="RGBA(0, 82, 154, 0.215)" />
                </g>
                </svg>
      <!--Waves end-->

                
    </div>
        <!-- End First Section -->

        

    <!-- Second Section -->
    <section class="services">
      <div class="container">
        <div class="row text-center">

          <div class="col-md-12 col-lg-4 d-flex align-items-stretch" data-aos="fade-up">
            <div class="icon-box icon-box-green">
              <div class="icon"><v-icon class="card-icon">mdi-draw</v-icon></div>
                <h4 class="title">Sign</h4>
                <p class="description">Providing a convenient and secure way to sign documents without the need for printing, physically signing, and scanning.</p>
            </div>
          </div>

            <div class="col-md-12 col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
              <div class="icon-box icon-box-pink">
                <div class="icon"><v-icon class="card-icon">mdi-lock</v-icon></div>
                  <h4 class="title">Secure</h4>
                    <p class="description">Prioritizing the security and protection of your documents and electronic signatures. We employ robust security measures to ensure the confidentiality, integrity, and non-repudiation of your signed documents.</p>
              </div>
            </div>

            <div class="col-md-12 col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
              <div class="icon-box icon-box-cyan">
                <div class="icon"><v-icon class="card-icon">mdi-share-variant-outline</v-icon></div>
                  <h4 class="title">Share</h4>
                    <p class="description">This feature,allows users to securely and efficiently share your electronically signed documents with recipients, whether they are colleagues, clients, or other stakeholders.</p>
              </div>
            </div>

        </div>
      </div>
    </section>
    <!-- End Second Section -->

    <!-- Third Section -->
    <section class="services">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-12 col-lg-6 d-flex align-items-stretch" v-scrollanimation>
            <v-card
              class="mx-auto"
              height="200px"
            >
              <div class="row">
                <div class="col-4"> 
                  <v-img
                    contain
                    class="white--text align-end"
                    height="200px"
                    src="../../../public/personheirachy.png"
                  >
                  </v-img>
                </div>
                <div class="col-8">
                  <v-card-text class="text--primary">
                    <h1 class="title">Hierarchical Document Sharing</h1>

                    <p class="description">Share and collaborate on documents effortlessly with our hierarchical document sharing feature.</p>
                  </v-card-text>
                </div>
              </div>
            </v-card>
          </div>

          <div class="col-md-12 col-lg-6 d-flex align-items-stretch"  v-scrollanimation>
            <v-card
              class="mx-auto"
              height="200px"
            >
              <div class="row">
                <div class="col-4"> 
                  <v-img
                    class="white--text align-end"
                    height="170px"
                    contain
                    src="../../../public/personlinked.png"
                  >
                  </v-img>
                </div>
                <div class="col-8">
                  <v-card-text class="text--primary">
                    <h1 class="title">Progress Updates</h1>

                    <p class="description">Stay on top of shared documents with progress tracking,
                      you can monitor the status of shared documents and receive updates from recipients as they make progress.</p>
                  </v-card-text>
                </div>
              </div>
            </v-card>
          </div>

          <div class="col-md-12 col-lg-6 d-flex align-items-stretch" v-scrollanimation>
            <v-card
              class="mx-auto"
              height="200px"
            >
              <div class="row">
                <div class="col-4"> 
                  <v-img
                    contain
                    class="white--text align-end"
                    height="200px"
                    src="../../../public/rejectfile.png"
                  >
                  </v-img>
                </div>
                <div class="col-8">
                  <v-card-text class="text--primary">
                    <h1 class="title">Easy Document Rejection</h1>

                    <p class="description">Take control of your workflow by allowing recipients to reject shared documents that are not relevant to them. </p>
                  </v-card-text>
                </div>
              </div>
            </v-card>
          </div>

          <div class="col-md-12 col-lg-6 d-flex align-items-stretch" v-scrollanimation>
            <v-card
              class="mx-auto"
              height="200px"
            >
              <div class="row">
                <div class="col-4"> 
                  <v-img
                    contain
                    height="200px"
                    src="../../../public/email.png"
                  >
                  </v-img>
                </div>
                <div class="col-8">
                  <v-card-text class="text--primary">
                    <h1 class="title">Instant Notifications</h1>

                    <p class="description">Never miss an important update again! Receive instant notifications when documents are shared with you, keeping you informed and in the loop at all times.</p>
                  </v-card-text>
                </div>
              </div>
            </v-card>
          </div>

          

        </div>
      </div>
    </section>
    <!-- End Third Section -->
    <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
      <div class="container">
        <div class="copyright">
          &copy; Copyright <strong><span>e-GA</span></strong>. All Rights Reserved
        </div>
        <div class="credits">
            Designed by e-Government Authority
        </div>
      </div>
    </footer>

  </div>
</template>

<script>

export default{


}

</script>

<style>

/*--------------------------------------------------------------
# Nav
--------------------------------------------------------------*/
.nav{
  position: fixed;
  width: 100%;
}





.header{
    height: 90vh;
    background-image: url("../../../public/background1.png");
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.section1{
  background-image: linear-gradient(aliceblue,#00529a);
}

.centered-content {
  position: relative;
  top: 250px;
  padding: 30px;
  margin: auto;
}



.waves {
  position:relative;
  width: 100%;
  height:30vh;
  bottom: 0%;
  min-height:100px;
  max-height:150px;
}


/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #286994;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-newsletter {
  padding: 50px 0;
  background: #0d2735;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  color: #a2cce3;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
}

#footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  margin: 3px;
  background: #68A4C4;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}

#footer .footer-newsletter form input[type=submit]:hover {
  background: #468db3;
}

#footer .footer-top {
  background: #0d2735;
  border-top: 1px solid #17455e;
  border-bottom: 1px solid #123649;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 18px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  color: #a2cce3;
  font-weight: 600;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #1e4356;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #68A4C4;
  color: #fff;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 18px;
  font-weight: 600;
  color: #a2cce3;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #a2cce3;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #a2cce3;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

#footer .credits a {
  color: #a2cce3;
}


/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}

@keyframes fade-in {
  0% {
      opacity: 0;
      transform: translateX(20px);
   }
   100% {
      opacity: 1;
      transform: translateX(0px);
   }
}
.animate {
     animation: fade-in 1.75s ;
}

.before-enter {
  opacity:0;
  transform: translateY(100px);
  transition:all 2s ease-out
}

.enter  {
  opacity:1;
  transform: translateY(0px);
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-primary {
  font-family: "Roboto", sans-serif;
}

/* services*/
.services {
  padding-bottom: 20px;
  background-color: #00529a;
}

.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  margin: 0 0 40px 0;
  background: #fff;
  box-shadow: 0 5px 26px 0 rgba(68, 88, 144, 0.14);
  transition: all 0.3s ease-in-out;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 10px;
}

.services .icon {
  margin: 0 auto 20px auto;
  padding-top: 17px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 72px;
  height: 72px;
}

.services .icon i {
  font-size: 36px;
  line-height: 1;
}

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .title a {
  color: #111;
}

.services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.services .icon-box-pink .icon {
  background: #fceef3;
}

.services .icon-box-pink .icon i {
  color: #ff689b;
}

.services .icon-box-pink:hover {
  border-color: #ff689b;
}

.services .icon-box-cyan .icon {
  background: #e6fdfc;
}

.services .icon-box-cyan .icon i {
  color: #3fcdc7;
}

.services .icon-box-cyan:hover {
  border-color: #3fcdc7;
}

.services .icon-box-green .icon {
  background: #eafde7;
}

.services .icon-box-green .icon i {
  color: #41cf2e;
}

.services .icon-box-green:hover {
  border-color: #41cf2e;
}

.services .icon-box-blue .icon {
  background: #e1eeff;
}

.services .icon-box-blue .icon i {
  color: #2282ff;
}

.services .icon-box-blue:hover {
  border-color: #2282ff;
}



</style>