<template>
    <v-row justify="center">
      <v-col class="text-right">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="800px"
        >
            <v-card>
            <v-card-title>
                <span class="text-h5">Share Document</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-autocomplete
                          label="Institutions:"
                          auto-select-first
                          v-model="institutionsUuid"
                          :items="institutions"
                          item-text="name"
                          item-value="uuid"
                          @change="onInstitutionSelected"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete
                          :disabled="selected"
                          label="Share to:"
                          auto-select-first
                          chips
                          clearable
                          deletable-chips
                          multiple
                          v-model="userUuid"
                          :items="institutionsuserlist"
                          item-text="username"
                          item-value="uuid"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="closeModal"
            >
              Close
            </v-btn>
            <v-btn
              color="green"
              text
              @click="onSubmit"
            >
              Share
            </v-btn>
          </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
       
    </v-row>   
  </template>
  
  
  
  <script>
  import { mapGetters } from 'vuex';
  export default {
    props:{
        dialog:Boolean,
        Docuuid:String,
    },

    data(){
        return{
            userUuid:'',
            institutionsUuid:'',
            selected:true,
        }
    },

    mounted(){
      this.$store.dispatch('LoadInstitutions');
      
    },

    computed:{
    
    ...mapGetters(['getInstitutionUsersList']),

    ...mapGetters(['getInstitutions']),

        institutions(){
            return this.getInstitutions
        },

      institutionsuserlist(){
        console.log(this.getInstitutionUsersList);
      return this.getInstitutionUsersList;
      },

    },


    methods:{

        onSubmit() {
            const  docDto ={
                docUuid:this.Docuuid,
                userUuid:this.userUuid,
            }
            console.log(docDto);
            this.$store.dispatch('ShareDocument',docDto);
            this.dialog = false;
            this.$emit('close'); // Emit the 'close' event to the parent component
        },

        closeModal() {
            this.dialog = false;
            this.$emit('close'); // Emit the 'close' event to the parent component
        },
  
        onInstitutionSelected(){
          this.selected=false;
          this.$store.dispatch('LoadInstitutionUsersList',this.institutionsUuid);
        },
    }


  }
  </script>
  
  <style>
  /* .v-select__selections {
    overflow:scroll;
    flex-wrap: nowrap;
  }

  .v-chip {
    overflow: initial;
  } */

  </style>
  