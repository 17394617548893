<!-- <template>

    <div>
        <div id="id1" draggable="true" ondragstart="onDragStart(event)" style="border:2px solid green; cursor:pointer;width:100px;height:50px;">Dragged Div</div>


        <div id="id2" style="position:absolute;left:200px;top:50px;border:2px solid red; cursor:pointer;width:200px;height:200px;" ondrop="drop_handler(event)" ondragover="dragover_handler(event)">Drop Div
        </div>
    </div>


</template>

<script>
import {onDragStart,drop_handler,dragover_handler} from '../../utils/DragDrop.js'
export default({
    mounted() {
        onDragStart(event);
        drop_handler(event);
        dragover_handler(event);
    },
    actions:{
        
    },
    methods:{
       
    },
})

</script> -->



<!-- <template>
  <div>
    <embed :src="pdfFile" type="application/pdf" width="100%" height="600px">
    <canvas ref="signatureCanvas" width="400" height="200"></canvas>
    <button @click="saveSignature">Save signature</button>
    <button @click="addSignature">Add signature</button>
  </div>
</template>

<script>
//import { saveAs } from 'file-saver';
import SignaturePad from 'signature_pad';
//import{ degrees, PDFDocument, rgb} from 'pdf-lib';
import{PDFDocument}from 'pdf-lib'
export default {
  data() {
    return {
      signaturePad: null,
      signatureDataUrl: null,
      pdfDataUrl: null
    }
  },
  mounted() {
    this.initializeSignaturePad();
    this.initializePdf();
  },
  methods: {
    initializeSignaturePad() {
      const canvas = this.$refs.signatureCanvas;
      this.signaturePad = new SignaturePad(canvas);
    },
    saveSignature() {
      this.signatureDataUrl = this.signaturePad.toDataURL();
    },
   async addSignature() {

  try {
        const pdfFile = this.$store.state.filepath;
        const response = await fetch(pdfFile);
        const pdfBlob = await response.blob();

        const pdfDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());
        const pngImageBytes = await fetch( this.signatureDataUrl).then((res) =>
          res.arrayBuffer()
        );

        const pngImage = await pdfDoc.embedPng(pngImageBytes);
        const page = pdfDoc.getPages()[0];
        //const { width, height } = page.getSize();

        const imageSize = pngImage.scale(0.5);

        page.drawImage(pngImage, {
          // x: width / 2 - imageSize.width / 2,
          // y: height / 2 - imageSize.height / 2,
          x: 0,
          y: 0,
          width: imageSize.width,
          height: imageSize.height,
        });

        const pdfBytes = await pdfDoc.save();
        const pdfDataUrl = URL.createObjectURL(
          new Blob([pdfBytes], { type: "application/pdf" })
        );

        this.pdfDataUrl = pdfDataUrl;
      } catch (error) {
        console.error(error);
      }


    //****************important to
       // Fetch an existing PDF document
    //    const url = this.$store.state.filepath;
  	// 	const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

    //   // Load a PDFDocument from the existing PDF bytes
    //   const pdfDoc = await PDFDocument.load(existingPdfBytes)


    //   // Get the first page of the document
    //   const pages = pdfDoc.getPages()
    //   const firstPage = pages[0]

    //   // Get the width and height of the first page
    //   const { height } = firstPage.getSize()

    //   // Draw a string of text diagonally across the first page
    //   firstPage.drawText('This text was added with JavaScript!', {
    //     x: 5,
    //     y: height / 2 + 300,
    //     size: 50,
    //     color: rgb(0.95, 0.1, 0.1),
    //     rotate: degrees(-45),
    //   })

    //   // Serialize the PDFDocument to bytes (a Uint8Array)
    //   const pdfBytes = await pdfDoc.save()

		// 	// Trigger the browser to download the PDF document
    //   saveAs(new Blob([pdfBytes], { type: "application/pdf" }), "modified-pdf-file.pdf");
    // //*************important to
    },
    async initializePdf() {
      try {
        const pdfFile = this.$store.state.filepath;
        const response = await fetch(pdfFile);
        const pdfBlob = await response.blob();
        const fileReader = new FileReader();
        fileReader.readAsDataURL(pdfBlob);
        fileReader.onloadend = () => {
      this.pdfDataUrl = fileReader.result;
    };
  } catch (error) {
    console.error(error);
  }

    }
  },
  computed: {
    pdfFile() {
      
      return this.pdfDataUrl ? this.pdfDataUrl : '';
    }
  }
};
</script> -->


<!-- 
 
<template>
  <div>
    <div>
      <embed :src="pdfFile" type="application/pdf" width="800px" height="900px">
    </div>
     <img src="../../assets/images.png" alt="Your Image" id="hover-image" @dragend="onMouseOver" :style="hoverImageStyle"/>
   <canvas ref="signatureCanvas" width="400" height="200"></canvas>
     <button @click="saveSignature">Save signature</button>
    <button @click="addSignature">Add signature</button> 
  </div>
</template>

<script>
import SignaturePad from 'signature_pad';
import{PDFDocument}from 'pdf-lib'
export default {
  data() {
    return {
      signaturePad: null,
      signatureDataUrl: null,
      pdfDataUrl: null,
      mouseX: 0,
      mouseY: 0
    }
  },
  mounted() {
    this.initializeSignaturePad();
    this.initializePdf();
  },
  methods: {
    initializeSignaturePad() {
      const canvas = this.$refs.signatureCanvas;
      this.signaturePad = new SignaturePad(canvas);
    },
    saveSignature() {
      this.signatureDataUrl = this.signaturePad.toDataURL();
    },
   async addSignature() {

  try {
        const pdfFile = this.$store.state.filepath;
        const response = await fetch(pdfFile);
        const pdfBlob = await response.blob();

        const pdfDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());
        const pngImageBytes = await fetch( this.signatureDataUrl).then((res) =>
          res.arrayBuffer()
        );

        const pngImage = await pdfDoc.embedPng(pngImageBytes);
        const page = pdfDoc.getPages()[0];

        const imageSize = pngImage.scale(0.5);

        page.drawImage(pngImage, {
          x: this.mouseX,
          y: this.mouseY,
          width: imageSize.width,
          height: imageSize.height,
        });

        const pdfBytes = await pdfDoc.save();
        const pdfDataUrl = URL.createObjectURL(
          new Blob([pdfBytes], { type: "application/pdf" })
        );

        this.pdfDataUrl = pdfDataUrl;
      } catch (error) {
        console.error(error);
      }
    },
    async initializePdf() {
      try {
        const pdfFile = this.$store.state.filepath;
        const response = await fetch(pdfFile);
        const pdfBlob = await response.blob();
        const fileReader = new FileReader();
        fileReader.readAsDataURL(pdfBlob);
        fileReader.onloadend = () => {
      this.pdfDataUrl = fileReader.result;
    };
  } catch (error) {
    console.error(error);
  }
    },

    onMouseOver(event) {
      const pdfEmbed = document.getElementsByTagName("embed")[0];
      this.mouseX = event.clientX - pdfEmbed.getBoundingClientRect().left;
      this.mouseY = event.clientY - pdfEmbed.getBoundingClientRect().top;
      console.log(this.mouseX,this.mouseY);
    }
  },
  computed: {
    pdfFile() {
      
      return this.pdfDataUrl ? this.pdfDataUrl : '';
    },
    hoverImageStyle() {
      return {
        position: 'absolute',
        left: `${this.mouseX}px`,
        top: `${this.mouseY}px`,
        width: '200px',
        height: '200px',
        zIndex: 1
      }
    }
  },
};
</script>  -->





<!-- tttttt -->

<template>
  <div>
    <div @mousemove="mouseMove" style="height: 90vh;">
      <embed :src="pdfFile" type="application/pdf" width="100%" height="100%" ref="pdfEmbed"  id="iframe">
        <canvas></canvas>
    </div>
    <img src="../../assets/images.png" alt="Your Image" id="hover-image"  @dragend="onMouseMove" :style="hoverImageStyle"/>
    <canvas ref="signatureCanvas" width="400" height="200" ></canvas>
    <button @click="saveSignature">Save signature</button>
    <button @click="addSignature">Add signature</button>
  </div>
</template>

<script>
import SignaturePad from 'signature_pad';
import{PDFDocument}from 'pdf-lib'
export default {
  data() {
    return {
      signaturePad: null,
      signatureDataUrl: null,
      pdfDataUrl: null,
      mouseX: 0,
      mouseY: 0,
      pdfPageRect: null,
    }
  },
  mounted() {
    this.initializeSignaturePad();
    this.initializePdf();
    this.pdfPageRect = this.$refs.pdfEmbed.getClientRects()[0];

    
  },
  methods: {
    initializeSignaturePad() {
      const canvas = this.$refs.signatureCanvas;
      this.signaturePad = new SignaturePad(canvas);
    },
    saveSignature() {
      this.signatureDataUrl = this.signaturePad.toDataURL();
    },
   async addSignature() {

  try {
        const pdfFile = this.$store.state.filepath;
        const response = await fetch(pdfFile);
        const pdfBlob = await response.blob();

        const pdfDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());
        const pngImageBytes = await fetch( this.signatureDataUrl).then((res) =>
          res.arrayBuffer()
        );

        const pngImage = await pdfDoc.embedPng(pngImageBytes);
        const page = pdfDoc.getPages()[0];

        const imageSize = pngImage.scale(0.5);

        page.drawImage(pngImage, {
          x: 0,
          y: 0,
          width: imageSize.width,
          height: imageSize.height,
        });

        const pdfBytes = await pdfDoc.save();
        const pdfDataUrl = URL.createObjectURL(
          new Blob([pdfBytes], { type: "application/pdf" })
        );

        this.pdfDataUrl = pdfDataUrl;
      } catch (error) {
        console.error(error);
      }
    },
    async initializePdf() {
      try {
        const pdfFile = this.$store.state.filepath;
        const response = await fetch(pdfFile);
        const pdfBlob = await response.blob();
        const fileReader = new FileReader();
        fileReader.readAsDataURL(pdfBlob);
        fileReader.onloadend = () => {
      this.pdfDataUrl = fileReader.result;
      // document.getElementById('pdf').src = this.pdfDataUrl;
    };
  } catch (error) {
    console.error(error);
  }
    },

    onMouseMove(event) {
      const pdfPageX = this.pdfPageRect.left + window.pageXOffset;
      const pdfPageY = this.pdfPageRect.top + window.pageYOffset;
      this.mouseX = event.clientX - pdfPageX;
      this.mouseY = event.clientY - pdfPageY;
      console.log(this.mouseX,this.mouseY);
    },

    mouseMove(event) {
      const pdfPageX = this.pdfPageRect.left + window.pageXOffset;
      const pdfPageY = this.pdfPageRect.top + window.pageYOffset;
      console.log( event.clientX - pdfPageX,event.clientY - pdfPageY);
        }

  },
  computed: {
    pdfFile() {
      
      return this.pdfDataUrl ? this.pdfDataUrl : '';
    },
    hoverImageStyle() {
      return {
        position: 'absolute',
        left: `${this.mouseX}px`,
        top: `${this.mouseY}px`,
        width: '200px',
        height: '200px',
        zIndex: 1,
        
      }
    }
  },
};
</script>  
   

<!-- ttttt -->




<!-- 
<template>
  <div ref="pdfContainer" @dragstart="onDragStart" @drop="onDrop"></div>
</template>

<script>
import { PDFDocument } from 'pdf-lib';

export default {
  data() {
    return {
      pdfDoc: null,
      page: null,
      pdfBytes: null,
      width: 0,
      height: 0,
    };
  },
  methods: {
    async onDrop(event) {
      event.preventDefault();

      // Get the dropped image data
      const imageUrl = event.dataTransfer.getData('text/plain');
      const imageBytes = await fetch(imageUrl).then(res => res.arrayBuffer());

      // Embed the image in the PDF document
      const image = await this.pdfDoc.embedPng(imageBytes);

      // Get the drop location
      const { left, top } = this.$refs.pdfContainer.getBoundingClientRect();
      const x = event.clientX - left;
      const y = top - event.clientY;

      // Add the image to the PDF page at the drop location
      this.page.drawImage(image, {
        x,
        y,
        width: 200,
        height: 200,
      });

      // Save the PDF document
      this.pdfBytes = await this.pdfDoc.save();
    },
    onDragStart(event) {
      event.dataTransfer.setData('text/plain', event.target.src);
    },
  },
  async mounted() {
    // Create a new PDF document
    this.pdfDoc = await PDFDocument.create();

    // Get the first page of the document and its dimensions
    this.page = this.pdfDoc.addPage();
    const { width, height } = this.page.getSize();
    this.width = width;
    this.height = height;
  },
};
</script> -->

<!--  
<template>
  <div>
    <canvas ref="pdfCanvas" @mousemove="handleMouseMove"></canvas>
  </div>
</template>

<script>
import { PDFDocument } from 'pdf-lib';

export default {
  data() {
    return {
      pdfDoc: null,
      currentPage: 1,
      mouseX: null,
      mouseY: null
    }
  },
  mounted() {
    this.loadPdf();
  },
  methods: {
    async loadPdf() {
      const url = this.$store.state.filepath;
      const response = await fetch(url);
      const pdfData = await response.arrayBuffer();
      this.pdfDoc = await PDFDocument.load(pdfData);
      this.renderPage();
    },
    async renderPage() {
      const canvas = this.$refs.pdfCanvas;
      const ctx = canvas.getContext('2d');

      const page = this.pdfDoc.getPage(this.currentPage);
      // const viewport = page.getViewport({ scale: 1 });
      // canvas.width = viewport.width;
      // canvas.height = viewport.height;

      const pdfBytes = await this.pdfDoc.save();
      const pdfDataUri = `data:application/pdf;base64,${btoa(String.fromCharCode(...new Uint8Array(pdfBytes)))}`;

      const pdfImage = new Image();
      pdfImage.src = pdfDataUri;
      pdfImage.onload = () => {
        ctx.drawImage(pdfImage, 0, 0);
      };
    },
    handleMouseMove(event) {
      const canvas = this.$refs.pdfCanvas;
      const canvasRect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - canvasRect.left;
      const mouseY = event.clientY - canvasRect.top;
      this.mouseX = mouseX;
      this.mouseY = mouseY;
      console.log(`Mouse position on page ${this.currentPage}: ${mouseX} x ${mouseY}`);
    }
  }
}
</script>  -->

<!-- <template>
  <div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import { PDFDocument } from 'pdf-lib';

export default {
  mounted() {
    this.displayPDF();
  },
  methods: {
    async displayPDF() {
      const pdfFile = this.$store.state.filepath;
      const response = await fetch(pdfFile);
      const pdfBlob = await response.blob();

      const pdfData = await this.readBlobAsArrayBuffer(pdfBlob);
      const pdfDoc = await PDFDocument.load(pdfData);

      const pageIndex = 0; // Index of the page to display
      const page = pdfDoc.getPages()[pageIndex];
      const viewport = page.viewport()

      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({ canvasContext: context, viewport });
    },
    readBlobAsArrayBuffer(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    },
  },
};
</script> -->


<!-- <template>
  <div id="pageContainer">
    <div id="viewer" class="pdfViewer"></div>
  </div>
</template>
 
<script>
import pdfjsLib from "pdfjs-dist/build/pdf";
import { PDFViewer } from "pdfjs-dist/web/pdf_viewer";
import "pdfjs-dist/web/pdf_viewer.css";
 
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.worker.min.js";
 
export default {
  name: "PdfViewer",
  mounted() {
    this.getPdf();
  },
  methods: {
    async getPdf() {
      let container = document.getElementById("pageContainer");
      let pdfViewer = new PDFViewer({
        container: container
      });
      let loadingTask = pdfjsLib.getDocument(this.$store.state.filepath);
      let pdf = await loadingTask.promise;
      pdfViewer.setDocument(pdf);
    }
  }
};
</script>
 
<style>
#pageContainer {
  margin: auto;
  width: 80%;
}
 
div.page {
  display: inline-block;
}
</style>  -->

<!-- 
<template>
  <div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import pdfjsLib from 'pdfjs-dist/webpack';

export default {
  mounted() {
    // Path to your PDF file
    const pdfUrl = this.$store.state.filepath;

    // Options for PDF.js
    const options = null;

    // Fetch the PDF file
    pdfjsLib.getDocument(pdfUrl, options)
      .promise.then(pdf => {
        // Get the first page
        return pdf.getPage(1);
      })
      .then(page => {
        // Set up the canvas element
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');
        const viewport = page.getViewport({ scale: 1 });

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        // Render the PDF page onto the canvas
        page.render({
          canvasContext: context,
          viewport: viewport
        });
      });
  }
};
</script>  -->
