<template>
    <v-row justify="center">
      <v-col class="text-right">
            <v-dialog
                v-model="dialog"
                persistent
                max-width="600px"
            >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                outlined
                color="primary"
                rounded
                link
                class="text-capitalize mt-4 ml-5"
                v-bind="attrs"
                v-on="on"
            >
                Add Institution
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Add Institution</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Name*"
                      v-model="name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Acronym*"
                      v-model="acronym"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Email*"
                      v-model="email"
                      :rules="emailRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Phone*"
                      v-model="phone"
                      placeholder="255"
                      :rules="phonerules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                text
                @click="closeModal"
              >
                Close
              </v-btn>
              <v-btn
                color="green"
                text
                @click="onSubmit"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
       
    </v-row>   
  </template>
  
  
  <script>
  
  
  import { mapGetters } from 'vuex';
  export default{
  
    name: "Documents",
   
    data(){
      return{
        dialog:false,
        acronym:'',
        name:'',
        email:'',
        phone:'',
        emailRules: [
         value => !!value || 'Required.',    
        (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'E-mail must be valid'
        ],
        phonerules: [
            value => !!value || 'Required.',
            (value) => value && value.length >= 12 || 'Please put a valid number',
        ],
      }
    },
    methods:{
      closeModal() {
        this.dialog = false;
      },
     
      onSubmit() {
        const  institution ={
            name:this.name,
            acronym:this.acronym,
            email:this.email,
            phone:this.phone,
        }
        this.$store.dispatch('createUpdateInstitutions', { institution });
        this.dialog = false;
      },
    },
  
    components: { 
    },
  
    
    computed:{
      ...mapGetters(['getRoles']),
        roles()
        {
          return this.getRoles;
        },
    },
  }  
  
  </script>
  
  
  
  <style>
  
  </style>
  
  