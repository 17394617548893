
<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        transition="dialog-b"
        max-width="600"
        v-model="isIdle"
      >
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >Session Expired</v-toolbar>
            <v-card-text>
              <p class="mt-5">You have left this browser idle for 15 minutes.</p>
              <p>You will be <b>logged out in {{ time }} seconds</b>, move cursor to stop this action.</p>
            </v-card-text>
          </v-card>
      </v-dialog>
    </v-col>

  </v-row>
</template>
<script>
export default {
  props: ["isIdle"],
  data() {
    return {
      time: 15,
    };
  },
  created() {
    let timerId = setInterval(() => {
      this.time -= 1;
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
      if (this.time < 1) {
        clearInterval(timerId);
        this.$store.dispatch("logoutHandler")
      }
    }, 1000);
  }
};
</script>

<style lang="postcss" scoped>


</style>