<!-- <template>
  <div>
    <h1>hello world</h1>
    <iframe id="pdf" frameborder="0" height="595px" width="842px" ></iframe>
  </div>
</template>

<script>
import { PDFDocument } from 'pdf-lib';
export default {
  data(){
    return{
      pdfDocument:null,
    };
  },
  async mounted(){
    try{
      const url = this.$store.state.filepath;
      const response = await fetch(url);
      const pdfData = await response.arrayBuffer();
      this.pdfDocument = await PDFDocument.load(pdfData);
      const viewerPrefs = this.pdfDocument.catalog.getOrCreateViewerPreferences(); 
    console.log(viewerPrefs); 
      const pdfDataUri = await this.pdfDocument.saveAsBase64({dataUri:true});
      document.getElementById('pdf').src = pdfDataUri;
    } catch (error) {
      console.error('Error loading PDF:',error);
    }
  },
}
</script> -->
<!-- 
<template>
  <div>
    <div class="toolbar_div">
        <button @click="addSignature"><v-icon>mdi-chevron-left</v-icon></button>
        <input type="text" id="current_page"> of 2
        <button><v-icon>mdi-chevron-right</v-icon></button>
    </div>
    <div class="signitureOverlay_div">
      <canvas id="signature_canvas"></canvas>
    </div>
    <div class="document_div">
      <canvas ref="pdfCanvas"></canvas>
    </div>
    
  </div>
</template>

<script>
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist/legacy/build/pdf';

export default {
  data(){
    return{
     
    }
    
  },
  mounted() {
   this.initializePdf(); 
   const canvas = document.getElementById('signature_canvas');
   canvas.addEventListener('mousemove', this.handleMouseMove);
   canvas.addEventListener('mousedown', this.handleMouseDown);
  },
  methods:{

    initializePdf(){
      const pNo=1;
      const canvas = this.$refs.pdfCanvas;
      const pdfUrl = this.$store.state.filepath; // Replace with the path or URL to your PDF file

      GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.7.107/pdf.worker.js'; // Path to the pdf.worker.js file

      getDocument(pdfUrl).promise.then((pdf) => {
        pdf.getPage(pNo).then((page) => {
          const viewport = page.getViewport({ scale: 1 });
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          page.render(renderContext);
          document.getElementById('current_page').value =pNo;
        });
      });
    },

    addSignature(){
      const canvas = document.getElementById('signature_canvas');
      const ctx = canvas.getContext('2d');
     

      let image = new Image();
      image.src = '/signiture.png';
      // let iw=image.width;
      // let ih=image.height;
      let sig_pic = {
        x: canvas.width/2,
        y: canvas.height/2,
        iWidth:100,
        iHeight:40
      };

      function animate() {
        ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
        ctx.drawImage(image, sig_pic.x, sig_pic.y, sig_pic.iWidth, sig_pic.iHeight);
        window.requestAnimationFrame(animate);
      }
      animate()

    },

    handleMouseMove(event) {
      let canvas=document.getElementById('signature_canvas');
      const canvasCoords = canvas.getBoundingClientRect();
      const mouseCoords = {
        x: event.pageX - canvasCoords.left,
        y: event.pageY - canvasCoords.top,
      };
      console.log(mouseCoords);
      // You can use the mouse coordinates here or perform any other desired actions
    },

    getMouseCoords(event){
      let canvas=document.getElementById('signature_canvas');
      let canvasCoords = canvas.getBoundingClientRect()
      //console.log(event.pageX - canvasCoords.left, event.pageY - canvasCoords.top);
      return {
          x: event.pageX - canvasCoords.left,
          y: event.pageY - canvasCoords.top
      }
    },

    cursorInRect(mouseX, mouseY, rectX, rectY, rectW, rectH){
      let xLine = mouseX > rectX && mouseX < rectX + rectW
      let yLine = mouseY > rectY && mouseY < rectY + rectH

      return xLine && yLine
      },

    handleMouseDown(event) {
      let canvas=document.getElementById('signature_canvas');
      // let this.sig_pic = {
      //   x: canvas.width/2,
      //   y: canvas.height/2,
      //   iWidth:100,
      //   iHeight:40
      // };
      let mouse = this.getMouseCoords(canvas, event);
      if (this.cursorInRect(mouse.x, mouse.y, this.sig_pic.x, this.sig_pic.y, this.sig_pic.edge, this.sig_pic.edge)) {
        return this.move=true;
      
      } else {
      
        return this.move=false;
      }
    },


    
  }
};
</script>
<style>
.document_div{
  /* background-color: aquamarine; */
  text-align: center;
  justify-content: center;
  margin:0 auto;
  width: 90%;
  padding: 0;
  z-index: 1;
}

.signitureOverlay_div{
  /* background-color: aqua; */
  position: absolute;
  text-align: center;
  z-index: 2;
  height: 80vh;
  width: 100%;
}

#signature_canvas{
  display: block;
  box-sizing: border-box;
  border: 1px solid hsl(259,100%,65%);
  margin: 0 auto;
  width: 645px;
  height: 842px;
}

.toolbar_div{
  background-color: gainsboro;
  text-align: center;
}

</style> -->

<!-- theone*********************************************************************** -->
<!-- <template>
  <div>
    <div class="toolbar_div">
        <button @click="addSignature">AddSigniture</button>
        &nbsp;
        <button ><v-icon>mdi-chevron-left</v-icon></button>
        &nbsp;
        <input type="text" id="current_page"> of 2
        &nbsp;
        <button><v-icon>mdi-chevron-right</v-icon></button>
        &nbsp;
        <button>Save Document</button>
    </div>
    <div class="signitureOverlay_div">
      <canvas id="signature_canvas"></canvas>
    </div>
    <div class="document_div">
      <canvas ref="pdfCanvas"></canvas>
    </div>
    
  </div>
</template>

<script>
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist/legacy/build/pdf';

export default {
  data(){
    return{
      canvas:document.getElementById('signature_canvas'),
      move:false,
      sig_pic: {
        x: 0,
        y:0, 
        iWidth:100,
        iHeight:40
      },
      image:new Image(),
    }
    
  },
  mounted() {
   this.initializePdf(); 
   const canvas = document.getElementById('signature_canvas');
   const ctx = canvas.getContext('2d');
   const w = canvas.width = window.innerWidth * 0.9/2.4
   const h = canvas.height  = window.innerHeight * 0.9/1.1
   canvas.addEventListener('mousemove', this.handleMouseMove);
   canvas.addEventListener('mousedown', this.handleMouseDown);

   this.image.src = '/signiture.png'

    const animate = () => {
      ctx.clearRect(0, 0, w, h)
      ctx.drawImage(this.image, this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth, this.sig_pic.iHeight)
      window.requestAnimationFrame(animate)
    }
    animate()
  },
  methods:{

    initializePdf(){
      const pNo=1;
      const canvas = this.$refs.pdfCanvas;
      const pdfUrl = this.$store.state.filepath; // Replace with the path or URL to your PDF file

      GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.7.107/pdf.worker.js'; // Path to the pdf.worker.js file

      getDocument(pdfUrl).promise.then((pdf) => {
        pdf.getPage(pNo).then((page) => {
          const viewport = page.getViewport({ scale: 1 });
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          page.render(renderContext);
          document.getElementById('current_page').value =pNo;
        });
      });
    },

    addSignature(){
      const canvas = this.$refs.pdfCanvas;     
      const pdfUrl = this.$store.state.filepath;
      // Load the PDF document
      getDocument(pdfUrl).promise.then(pdf => {
        // Get the first page of the PDF
        pdf.getPage(1).then(page => {
          // const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Load the image
          const image = new Image();
          image.src = '/signiture.png';
          image.onload = () => {
            // Set the canvas size to match the image
            // canvas.width = image.width;
            // canvas.height = image.height;

            // Draw the image on the canvas
            ctx.drawImage(image, this.sig_pic.x, this.sig_pic.y, 100, 40);

            // Get the canvas data URL
            const imageDataUrl = canvas.toDataURL();

            // Create a new annotation with the image data URL
            const annotation = {
              subtype: 'Image',
              image: {
                src: imageDataUrl
              },
              rect: [100, 100, 200, 200] // Specify the position and size of the image
            };

            // Add the annotation to the page
            page.addAnnotation(annotation);

            // Save the modified PDF
            pdf.saveDocument().then(data => {
              // Handle the saved PDF data
              console.log('Modified PDF saved:', data);
            });
          };
        });
      });
    },
   

    handleMouseMove(event) {
      let canvas=document.getElementById('signature_canvas');
      const canvasCoords = canvas.getBoundingClientRect();
     if(this.move){
        this.sig_pic.x = event.pageX - canvasCoords.left;
        this.sig_pic.y = event.pageY - canvasCoords.top;
     }
      
      // You can use the mouse coordinates here or perform any other desired actions
    },

    getMouseCoords(event){
      let canvas=document.getElementById('signature_canvas');
      let canvasCoords = canvas.getBoundingClientRect()
      return {
          x: event.pageX - canvasCoords.left,
          y: event.pageY - canvasCoords.top
      }
    },

    cursorInRect(mouseX, mouseY, rectX, rectY, rectW, rectH){
      let xLine = mouseX > rectX && mouseX < rectX + rectW
      let yLine = mouseY > rectY && mouseY < rectY + rectH

      return xLine && yLine
      },

    handleMouseDown(event) {
      let canvas=document.getElementById('signature_canvas');
      console.log("mousedown");
      
      let canvasCoords = canvas.getBoundingClientRect();
          let x= event.pageX - canvasCoords.left;
          let y= event.pageY - canvasCoords.top;
    
      console.log(x, y, this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth, this.sig_pic.iHeight);
      if (this.cursorInRect(x,y, this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth, this.sig_pic.iHeight)) {
        this.move=true;
      } else {
         this.move=false;
      }
      console.log(this.move);
    },


    
  }
};
</script>
<style>
.document_div{
  /* background-color: aquamarine; */
  text-align: center;
  justify-content: center;
  margin:0 auto;
  width: 90%;
  padding: 0;
  z-index: 1;
}

.signitureOverlay_div{
  /* background-color: aqua; */
  position: absolute;
  text-align: center;
  z-index: 2;
  height: 80vh;
  width: 100%;
}

#signature_canvas{
  display: block;
  box-sizing: border-box;
  border: 1px solid hsl(259,100%,65%);
  margin: 0 auto;
 
}

.toolbar_div{
  background-color: gainsboro;
  text-align: center;
}

</style> -->
<!-- ****************************************************** -->


<template>
  <div>
      <div class="Toolbar" id="toolbar">
        <v-card
          color="grey-lighten-4"
          flat
          height="70px"
          rounded="0"
        >
        <v-toolbar density="compact">
         
          <v-toolbar-title>Document Name</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <input part="input" type="text" id="pageSelector" aria-label="Page number">
          &nbsp;
          <span id="divider">/</span>
          &nbsp;
          <span id="pagelength">2</span>

          <v-btn icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn icon>
            <v-icon>mdi-draw-pen</v-icon>
          </v-btn>

          <v-btn icon>
            <v-icon>mdi-content-save</v-icon>
          </v-btn>

          <v-btn icon>
            <v-icon>mdi-share-variant-outline</v-icon>
          </v-btn>

          <v-btn icon>
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-toolbar>
        </v-card>
            <!-- <div class="row">
                <div class="col-4" >
                    <h2>Document Name</h2>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col-4 btn_prev" >
                            <button><v-icon>mdi-chevron-left</v-icon></button>
                        </div>
                        <div class="col-2" >
                            <input type="text" id="current_page"> of 2
                        </div>
                        <div class="col-4" >
                            <button><v-icon>mdi-chevron-right</v-icon></button>
                        </div>
                    </div>
                </div>
                <div class="col-2 btn_AddSave" >
                    <button>AddSigniture</button>
                    <button>Save Document</button>
                </div>
                <div class="col-2">

                </div>
            </div>            -->
        </div>
    <div class="signitureOverlay_div">
      <canvas id="signature_canvas"></canvas>
    </div>
    <div class="document_div">
      <canvas ref="pdfCanvas"></canvas>
    </div>
    
  </div>
</template>

<script>
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist/legacy/build/pdf';

export default {
  data(){
    return{
      canvas:document.getElementById('signature_canvas'),
      // ctx:this.canvas.getContext('2d'),
      move:false,
      sig_pic: {
        x: 0,
        y:0, 
        iWidth:100,
        iHeight:40
      },
      image:new Image(),
      // offsetX:this.canvas.offsetLeft,
      // offsetY:this.canvas.offsetTop,
      isDown:false,
      pi2:Math.PI*2,
      resizerRadius:8,
      rr:this.resizerRadius*this.resizerRadius,
      draggingResizer:{
        x:0,
        y:0
      },
      imageX:0,
      imageY:0,
      draggingImage:false,
      imageHeight:0,
      imageWidth:0,
      imageRight:0,
      imageBottom:0,
    }
    
  },
  mounted() {
   this.initializePdf(); 
  const canvas = document.getElementById('signature_canvas');
  const ctx = canvas.getContext('2d');
   const w =  canvas.width = window.innerWidth * 0.9/2.4
   const h =  canvas.height  = window.innerHeight * 0.9/1.1
   canvas.addEventListener('mousemove', this.handleMouseMove);
   canvas.addEventListener('mousedown', this.handleMouseDown);
   canvas.addEventListener('mouseup',this.handleMouseUp);

   this.image.src = '/signiture.png'

    const animate = () => {
      ctx.clearRect(0, 0, w, h)
      ctx.drawImage(this.image, this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth, this.sig_pic.iHeight)


      let imageX=this.sig_pic.x;
      let imageY=this.sig_pic.y;
      let imageRight=imageX+this.sig_pic.iWidth;
      let imageBottom=imageY+this.sig_pic.iHeight;

       this.drawDragAnchor(imageX, imageY);
       this.drawDragAnchor(imageRight, imageY);
       this.drawDragAnchor(imageRight, imageBottom);
       this.drawDragAnchor(imageX, imageBottom);
   

    // optionally draw the connecting anchor lines
    
    ctx.beginPath();
    ctx.moveTo(imageX, imageY);
    ctx.lineTo(imageRight, imageY);
    ctx.lineTo(imageRight, imageBottom);
    ctx.lineTo(imageX, imageBottom);
    ctx.closePath();
    ctx.stroke();
   

      window.requestAnimationFrame(animate)
    }
    animate()
  },
  methods:{

    initializePdf(){
      const pNo=1;
      const canvas = this.$refs.pdfCanvas;
      const pdfUrl = this.$store.state.filepath; // Replace with the path or URL to your PDF file

      GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.7.107/pdf.worker.js'; // Path to the pdf.worker.js file

      getDocument(pdfUrl).promise.then((pdf) => {
        pdf.getPage(pNo).then((page) => {
          const viewport = page.getViewport({ scale: 1 });
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          page.render(renderContext);
          document.getElementById('current_page').value =pNo;
        });
      });
    },

    addSignature(){
      const canvas = this.$refs.pdfCanvas;     
      const pdfUrl = this.$store.state.filepath;
      // Load the PDF document
      getDocument(pdfUrl).promise.then(pdf => {
        // Get the first page of the PDF
        pdf.getPage(1).then(page => {
          // const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Load the image
          const image = new Image();
          image.src = '/signiture.png';
          image.onload = () => {
            // Set the canvas size to match the image
            // canvas.width = image.width;
            // canvas.height = image.height;

            // Draw the image on the canvas
            ctx.drawImage(image, this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth, this.sig_pic.iHeight);

            // Get the canvas data URL
            const imageDataUrl = canvas.toDataURL();

            // Create a new annotation with the image data URL
            const annotation = {
              subtype: 'Image',
              image: {
                src: imageDataUrl
              },
              rect: [100, 100, 200, 200] // Specify the position and size of the image
            };

            // Add the annotation to the page
            page.addAnnotation(annotation);

            // Save the modified PDF
            pdf.saveDocument().then(data => {
              // Handle the saved PDF data
              console.log('Modified PDF saved:', data);
            });
          };
        });
      });
    },
   

    handleMouseMove(event) {

      let imageX=this.sig_pic.x;
      let imageY=this.sig_pic.y;
      let imageRight=imageX+this.sig_pic.iWidth;
      let imageBottom=imageY+this.sig_pic.iHeight;


      if (this.draggingResizer > -1) {
        console.log('onononon');
        const canvas=document.getElementById('signature_canvas');
        const topdiv=document.getElementById('toolbar');
       
            var offsetX = canvas.offsetLeft;
            var offsetY = canvas.offsetTop;
            let mouseX = parseInt(event.clientX - offsetX);
            let mouseY = parseInt(event.clientY - offsetY-topdiv.offsetHeight);

            console.log("boolean",this.draggingResizer);
            // resize the image
            switch (this.draggingResizer) {
                case 0:{
                   //top-left
                    imageX = mouseX;
                    this.imageWidth = imageRight - mouseX;
                    imageY = mouseY;
                    this.imageHeight = imageBottom - mouseY;
                    break;
                }
                   
                case 1:
                    //top-right
                    imageY = mouseY;
                   this.imageWidth = mouseX - imageX;
                   this.imageHeight = imageBottom - mouseY;
                    break;
                case 2:
                    //bottom-right
                    this.sig_pic.iWidth = mouseX - imageX;
                    this.sig_pic.iHeight = mouseY - imageY;
                    break;
                case 3:
                    //bottom-left
                    imageX = mouseX;
                    this.imageWidth = imageRight - mouseX;
                    this.imageHeight = mouseY - imageY;
                    break;
            }

            if(this.imageWidth<25){this.imageWidth=25;}
            if(this.imageHeight<25){this.imageHeight=25;}

            // set the image right and bottom
            this.imageRight = imageX + this.imageWidth;
            this.imageBottom = imageY + this.imageHeight;

            // redraw the image with resizing anchors
            // draw(true, true);

            } else if (this.draggingImage) {

           
                    let canvas=document.getElementById('signature_canvas');
                    const canvasCoords = canvas.getBoundingClientRect();
                  if(this.move){
                      this.sig_pic.x = event.pageX - canvasCoords.left;
                      this.sig_pic.y = event.pageY - canvasCoords.top;
                  }
            }



     
      
      
    },

    getMouseCoords(event){
      let canvas=document.getElementById('signature_canvas');
      let canvasCoords = canvas.getBoundingClientRect()
      return {
          x: event.pageX - canvasCoords.left,
          y: event.pageY - canvasCoords.top
      }
    },

    cursorInRect(mouseX, mouseY, rectX, rectY, rectW, rectH){
      let xLine = mouseX > rectX && mouseX < rectX + rectW
      let yLine = mouseY > rectY && mouseY < rectY + rectH

      return xLine && yLine
      },

    handleMouseDown(event) {
      let canvas=document.getElementById('signature_canvas');
      console.log("mousedown");
      const topdiv=document.getElementById('toolbar');
       
      
      let canvasCoords = canvas.getBoundingClientRect();
          let x= event.pageX - canvasCoords.left;
          let y= event.pageY - canvasCoords.top;
    
      console.log(x, y, this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth, this.sig_pic.iHeight);
      if (this.cursorInRect(x,y, this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth, this.sig_pic.iHeight)) {
        this.move=true;
      } else {
         this.move=false;
      }
      
      var offsetX = canvas.offsetLeft;
      var offsetY = canvas.offsetTop;
      this.startX = parseInt(event.clientX - offsetX-3);
      this.startY = parseInt(event.clientY - offsetY-topdiv.offsetHeight-3);
      console.log("event.clientX",event.clientX,"event.clientY",event.clientY);
      console.log(this.anchorHitTest(this.startX, this.startY));
      this.draggingResizer = this.anchorHitTest(this.startX, this.startY);
      this.draggingImage = this.draggingResizer < 0 && this.cursorInRect(x,y, this.sig_pic.x, this.sig_pic.y, this.sig_pic.iWidth, this.sig_pic.iHeight);
    },

    handleMouseUp() {
      this.draggingResizer = -1;
      this.draggingImage = false;
      // draw(true, false);
    },

    drawDragAnchor(x, y) {
      const canvas=document.getElementById('signature_canvas');
      const ctx=canvas.getContext('2d');
    ctx.beginPath();
    ctx.arc(x, y, this.resizerRadius, 0, this.pi2, false);
    ctx.closePath();
    ctx.fill();
    },

    anchorHitTest(x, y) {
      // let canvas=document.getElementById('signature_canvas');
      let imageRight=this.sig_pic.x+this.sig_pic.iWidth;
      let imageBottom=this.sig_pic.y+this.sig_pic.iHeight;
      let imageX=this.sig_pic.x;
      let imageY=this.sig_pic.y;


      var dx, dy;

      // top-left
      dx = x - this.sig_pic.x;
      dy = y - this.sig_pic.y;
      if (dx * dx + dy * dy <= 64) {
          return (0);
      }
      // top-right
      dx = x - imageRight;
      dy = y - imageY;
      if (dx * dx + dy * dy <= 64) {
          return (1);
      }
      // bottom-right
      dx = x - imageRight;
      dy = y - imageBottom;
      if (dx * dx + dy * dy <= 64) {
          return (2);
      }
      // bottom-left
      dx = x - imageX;
      dy = y - imageBottom;
      if (dx * dx + dy * dy <= 64) {
          return (3);
      }
      return (-1);

    }



    
  }
};
</script>
<style>
.document_div{
  /* background-color: aquamarine; */
  text-align: center;
  justify-content: center;
  margin:0 auto;
  width: 90%;
  padding: 0;
  z-index: 1;
}

.signitureOverlay_div{
  /* background-color: aqua; */
  position: absolute;
  text-align: center;
  z-index: 2;
  height: 80vh;
  width: 100%;
}

#signature_canvas{
  display: block;
  box-sizing: border-box;
  /* border: 1px solid hsl(259,100%,65%); */
  border: none;
  margin: 0 auto;
 
}

/* .toolbar_div{
  background-color: gainsboro;
  text-align: center;
} */

input{
  width: 30px;   
}

.Toolbar{
  /* background-image:linear-gradient(165deg,#0c2e4c,rgb(0, 100, 187)); */
   /* text-align: center; */
  /* padding-top: 10px;
  height: 50px;
  border: 5px;
  border-radius: 5px;
  border-bottom: #0c2e4c; */

}

.btn_prev{
  /* justify-content: end; */
  text-align: right;
}

.btn_AddSave{
  text-align: right;
}

</style>
