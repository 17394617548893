<template>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
        max-height="500px"
        eager
      >
        <v-card>
            <v-container>
                  <div style="display: flex; justify-content: center; background-color: white;" >
                    <embed/>
                    <canvas ref="signatureCanvas" id="signatureCanvas" style="border: 1px solid #00ffd5;" width="400" height="200"></canvas>
                  </div> 
                  &nbsp;
                  <div class="text-center">
                    <div>
                      <v-btn
                      @click="setCurrentColor('red')"
                      color="red" 
                      >
                      </v-btn>
                      &nbsp;
                      <v-btn
                      @click="setCurrentColor('black')"
                      color="black"
                      >
                      </v-btn>
                      &nbsp;
                      <v-btn
                      @click="setCurrentColor('green')"
                      color="green"
                      >
                      </v-btn>
                      &nbsp;
                      <v-btn
                      @click="setCurrentColor('blue')"
                      color="blue"
                      >
                      </v-btn>
                    </div>
                  </div>
                  &nbsp;
                  <v-row class="text-center">
                    <v-col cols="12">
                                <v-text>Upload signature</v-text>
                                <hr>
                            </v-col>
                            <v-col cols="6">
                            <input type="file" ref="file" @change="onFileSelected" accept="pdf"   style="width: 100%;">
                            </v-col>
                            <v-col cols="6">
                            <div id="preview">
                                <img v-if="url" :src="url" width="200px" height="100px"/>
                                <v-text v-if="!url" align="center" justify="center">Preview</v-text>
                            </div>
                            </v-col>
                  </v-row>
                   
                  &nbsp;             
            </v-container> 
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                    <v-container>
                      <v-text-field
                        v-model="oldpassphrase"
                        label="Old PassPhrase"
                        :rules="rules"
                        hide-details="auto"
                      ></v-text-field>
                      <v-text-field
                        v-model="newpassphrase"
                        label="New PassPhrase"
                        :rules="rules"
                        hide-details="auto"
                      ></v-text-field>
                      <v-text-field
                        v-model="confirmpassphrase"
                        label="Confirm PassPhrase"
                        :rules="confirmrule"
                        hide-details="auto"
                      ></v-text-field>
                    </v-container>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="resetSignature"
                      >
                        Reset
                      </v-btn>
                      <v-btn
                        color="red darken-1"
                        text
                        @click="back"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="validate"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <script>
  import SignaturePad from 'signature_pad';
  import { displayErrorSuccessMessages } from "../../components/SharedComponent/Notification/notify.js";

    export default {
      data (){
        return{
          dialog: true,
          signaturePad: null,
          signatureDataUrl: null,
          signaturePosition: { x: 100, y: 100 },
          isDragging: false,
          dragOffset: { x: 0, y: 0 },
          oldpassphrase:'',
          newpassphrase:'',
          confirmpassphrase:'',
          fileExtension:'',
          tab: null,
          url:"",
          rules: [
            value => !!value || 'Required.',
            (value) => value && value.length >= 8 || 'Password must be at least 8 characters',
            (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) || 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number',
          ],
          confirmrule: [
            value => !!value || 'Required.',
            (value) => value && value.length >= 8 || 'Password must be at least 8 characters',
            (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) || 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number',
            (value) => value === this.newpassphrase || 'Password do not match.',
          ],
        }
        
      },
      mounted() {
      this.initializeSignaturePad();
    },
      methods: {
        onFileSelected(event) {
          this.selectedFile = event.target.files[0];
          this.url=URL.createObjectURL(this.selectedFile);
          const parts = this.selectedFile.name.split(".");
          this.fileExtension = parts[parts.length - 1];
          if((this.fileExtension!= "png")&&(this.fileExtension!= "jpeg")&&(this.fileExtension!= "jpg")){
            console.log(this.fileExtension)
            const data = { 
              error: false,
              message:"Please upload correct format" 
            }
            displayErrorSuccessMessages(data);
            this.$refs.file.value = null;
          }else{
            this.url=URL.createObjectURL(this.selectedFile);
            this.resetSignature(); 
          }
        },
        back(){
            this.$router.go(-1);
        },
        validate () {
          const valid=this.$refs.form.validate();
          if(valid){
            if(this.selectedFile!=null){
              console.log("1");
              this.saveSignature(this.selectedFile);
              // this.back();
            }else{
              console.log("2");
              this.saveSignature(null);
              // this.back();
            }
            
            // this.back();
          }
        },
        // set all pixels of the image to this color
      setCurrentColor(color) {
        const canvas = this.$refs.signatureCanvas;
        var context = canvas.getContext('2d');
        context.save();
        context.fillStyle = color;
        context.globalCompositeOperation = 'source-in';
        context.fillRect(0,0,canvas.width, canvas.height);
        context.restore();
      },
      initializeSignaturePad() {
        // const canvas = this.$refs.signatureCanvas;
        const canvas = document.getElementById('signatureCanvas');
        this.signaturePad = new SignaturePad(canvas);      
        canvas.addEventListener('mousedown', this.startDragging);
        canvas.addEventListener('mousemove', this.drag);
        canvas.addEventListener('mouseup', this.stopDragging);
      },
      saveSignature(file) {

        if(file!=null){
          
          const reader = new FileReader()
          reader.readAsDataURL(this.selectedFile);


          reader.onload = () => {
            const base64 = reader.result.split(",")[1];
            const signatureDto ={
              fileName: "Signiture",
              extension:"."+this.fileExtension,
              base64Content:base64,
              oldPassphrase:this.oldpassphrase,
              newPassphrase: this.newpassphrase,
            }
           
            this.$store.dispatch('createUpdateSignature', { signatureDto });
          }
          reader.onerror = function(error) {
            alert(error);
          };
          this.back();
        }else{
          var sigImg = this.signaturePad.toDataURL('image/png');
          var index = sigImg.indexOf( "," )+1;
          const sigImgB64 = sigImg.substring( index );
          const signatureDto ={
                base64Content:sigImgB64,
                extension:".png",
                fileName: "Signiture",
                oldPassphrase:this.oldpassphrase,
                newPassphrase: this.newpassphrase,
              }
          this.$store.dispatch('createUpdateSignature', { signatureDto });
          this.back();
        }
       
     
     
      },
      resetSignature() {
        this.signaturePad.clear();
        this.signatureDataUrl = null;
        this.signaturePosition = { x: 100, y: 100 };
      },
      startDragging(event) {
        const { offsetX, offsetY } = event;
        if (
          offsetX > this.signaturePosition.x &&
          offsetX < this.signaturePosition.x + 100 &&
          offsetY > this.signaturePosition.y &&
          offsetY < this.signaturePosition.y + 50
        ) {
          this.isDragging = true;
          this.dragOffset = {
            x: offsetX - this.signaturePosition.x,
            y: offsetY - this.signaturePosition.y
          };
        }
      },
      drag(event) {
        if (this.isDragging) {
          const { offsetX, offsetY } = event;
          this.signaturePosition.x = offsetX - this.dragOffset.x;
          this.signaturePosition.y = offsetY - this.dragOffset.y;
        }
      },
      stopDragging() {
        this.isDragging = false;
        this.dragOffset = { x: 0, y: 0 };
      }
    },
  
  
  
  
    }
  </script>

<style>
#preview{
    height: 100px;
    width: 200px;
    background-color:gray;
    border-radius: 10px;
}
</style>