<template>
    <div>
        <!-- App-bar -->
        <v-app-bar dense app color="#0c2e4c" dark>
            <div class="d-flex align-center">
               <v-app-bar-title>e-Signature</v-app-bar-title> 
            </div>
            <v-spacer></v-spacer>
            <div class="ml-2">
            </div>
            <v-menu dense flat offset-y>
                <template v-slot:activator="{ on, attrs }">
                <v-btn plain color="white" dark v-bind="attrs" v-on="on">
                    <span color="white">{{ userdetails.email }}</span>
                    <v-icon right color="grey"> mdi-menu-down </v-icon>
                </v-btn>
                </template>
                <v-list dense>
                <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    router
                    :to="item.route"
                    link
                >
                    <v-list-item-icon>
                    <v-icon left>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <!-- end App-bar -->
        <v-container fill-height fluid>
            <v-row class="services1" justify="center" align="center">
                <v-col  cols="6">
                    <v-window v-model="step">
                        <v-window-item :value="1">
                            <div class="col-12 d-flex align-items-stretch justify-center">
                                <div class="icon-box icon-box-pink">
                                    <div class="icon"><v-icon class="card-icon">mdi-lock</v-icon></div>
                                        <h4 class="title">Reset Password</h4>
                                        <v-form
                                            ref="form"
                                            v-model="valid"
                                            lazy-validation
                                        >
                                            <v-container>
                                                <v-text-field
                                                v-model="Password"
                                                label="Given Password"
                                                hide-details="auto"
                                                ></v-text-field>
                                                &nbsp;
                                                <v-text-field
                                                v-model="newPassword"
                                                label="New Password"
                                                :rules="rules"
                                                hide-details="auto"
                                                ></v-text-field>
                                                &nbsp;
                                                <v-text-field
                                                v-model="confirmPassword"
                                                label="Confirm Password"
                                                :rules="confirmrules"
                                                hide-details="auto"
                                                ></v-text-field>
                                            </v-container>
                                            &nbsp;
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                color="green darken-1"
                                                text
                                                @click="validatepassword"
                                                >
                                                Save
                                                </v-btn>
                                            </v-card-actions>
                                        </v-form>
                                </div>
                            </div>
                        </v-window-item>
                        <v-window-item v-observe-visibility="visibilityChanged" :value="2">
                            <div class="col-12 d-flex align-items-stretch">
                                <div class="icon-box icon-box-cyan">
                                <div class="icon"><v-icon class="card-icon">mdi-draw</v-icon></div>
                                    <h4 class="title">Sign</h4>
                                    <v-card
                                    flat
                                    >
                                    <v-container>
                        <div style="display: flex; justify-content: center; background-color: white;" >
                           
                            <canvas ref="signatureCanvas" id="signatureCanvas" style="border: 1px solid #00ffd5;" width="400" height="200"></canvas>
                        </div> 
                        &nbsp;
                        <div class="text-center">
                            <div>
                            <v-btn
                            @click="setCurrentColor('red')"
                            color="red" 
                            >
                            </v-btn>
                            &nbsp;
                            <v-btn
                            @click="setCurrentColor('black')"
                            color="black"
                            >
                            </v-btn>
                            &nbsp;
                            <v-btn
                            @click="setCurrentColor('green')"
                            color="green"
                            >
                            </v-btn>
                            &nbsp;
                            <v-btn
                            @click="setCurrentColor('blue')"
                            color="blue"
                            >
                            </v-btn>
                            </div>
                        </div>
                        &nbsp;
                        <v-row>
                            <v-col cols="12">
                                <v-text>Upload signature</v-text>
                                <hr>
                            </v-col>
                            <v-col cols="6">
                            <input type="file" ref="file" @change="onFileSelected" accept="pdf"   style="width: 100%;">
                            </v-col>
                            <v-col cols="6">
                            <div id="preview">
                                <img v-if="url" :src="url" width="200px" height="100px"/>
                                <v-text v-if="!url" align="center" justify="center">Preview</v-text>
                            </div>
                            </v-col>
                        </v-row>
                        
                        &nbsp;             
                    </v-container> 
                        <v-form
                        ref="form2"
                        v-model="valid"
                        lazy-validation
                        >
                            <v-container>
                            <v-text-field
                                v-model="newpassphrase"
                                label="New PassPhrase"
                                :rules="rules"
                                hide-details="auto"
                            ></v-text-field>
                            <v-text-field
                                v-model="confirmpassphrase"
                                label="Confirm PassPhrase"
                                :rules="rules2"
                                hide-details="auto"
                            ></v-text-field>
                            </v-container>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="resetSignature"
                            >
                                Reset
                            </v-btn>
                            <!-- <v-btn
                                color="red darken-1"
                                text
                                @click="back"
                            >
                                Close
                            </v-btn> -->
                            <v-btn
                                color="green darken-1"
                                text
                                @click="validate"
                            >
                                Save
                            </v-btn>
                            </v-card-actions>
                        </v-form>
                                    </v-card>
                            </div>
                            </div>
                        </v-window-item>
                    </v-window>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import getUserProfile from "../../GraphQL/Users/UsersDetails.graphql";
import { mapGetters } from 'vuex';
import router from "../../router";
import SignaturePad from 'signature_pad';
import changePassword from '../../GraphQL/Auth/passwordChange.graphql';
import { apolloClient } from "@/vue-apollo";
import { displayErrorSuccessMessages } from "../../components/SharedComponent/Notification/notify.js";
import store from "@/store/index.js";
import 'v-visible';
    export default {


        data() {
            return {
                isVisible: false,
                dialog: '',
                signaturePad: null,
                signatureDataUrl: null,
                signaturePosition: { x: 100, y: 100 },
                isDragging: false,
                dragOffset: { x: 0, y: 0 },
                // passphrase:'',
                // oldpassphrase:'',
                newpassphrase:'',
                confirmpassphrase:'',
                Password:'',
                newPassword:'',
                confirmPassword:'',
                url:"",
                step:1,
                valid:"",
                rules: [
                    value => !!value || 'Required.',
                    (value) => value && value.length >= 8 || 'Password must be at least 8 characters',
                    (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) || 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number',
                ],
                rules2: [
                    value => !!value || 'Required.',
                    (value) => value && value.length >= 8 || 'Password must be at least 8 characters',
                    (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) || 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number',
                    (value) => value === this.newpassphrase || 'Password do not match.',
                ],
                confirmrules:[
                  value => !!value || 'Required.',
                  (value) => value && value.length >= 8 || 'Password must be at least 8 characters',
                  (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) || 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number',
                  (value) =>value === this.newPassword || 'Passwords do not match', 
                ],
                items: [
                    { icon: "mdi-power", title: "Logout", route: "/" },
                ],
            };
        },
        watch: {
           
        },

        computed:{
            ...mapGetters(['getUserDetails']),
            userdetails()
            {
            return this.getUserDetails;
            },
        },

        mounted(){
            this.checkuser();
            this.initializeSignaturePad();
            
            // this.$tours['Documents'].start();
           
        },
        methods: {

            visibilityChanged (isVisible) {
            this.isVisible = isVisible
            this.initializeSignaturePad();
            },



            onFileSelected(event) {
                this.selectedFile = event.target.files[0];
                const parts = this.selectedFile.name.split(".");
                this.fileExtension = parts[parts.length - 1];
                if((this.fileExtension!= "png")&&(this.fileExtension!= "jpeg")&&(this.fileExtension!= "jpg")){
                    
                    const data = { 
                    error: false,
                    message:"Please upload correct format" 
                    }
                    displayErrorSuccessMessages(data);
                    this.$refs.file.value = null;
                }else{
                    this.url=URL.createObjectURL(this.selectedFile);
                    this.resetSignature(); 
                }
                
            },
           
            async checkuser(){
                store.commit("setLoadingState", true);
                await apolloClient
                    .query({
                    fetchPolicy: "network-only",
                    query:getUserProfile,
                    variables: {
                    
                    },
                    })
                    .then((output) => {
                        const permissions=[];
                        
                        let data = output.data.getUserProfile;
                        
                        data.data.permissions.forEach(o =>{
                            permissions.push(o);
                        });
                        if(data.data.hasSignature){
                            store.commit("setLoadingState", false);
                            router.push("/landing/dashboard");
                        }
                        else{
                            store.commit("setLoadingState", false);
                        }
                    })
                    .catch((error) => {
                        store.commit("setLoadingState", false);
                    const data = { 
                        error: true,
                        message:error 
                    }
                    displayErrorSuccessMessages(data);
                    console.error("getUserDetails ==>", error);
                    });
            },

            hasSigniture(){
                const signiture = this.$store.getters.getUserDetails;
                const v =signiture.hasSignature;
                return v ? false : true;
            },

            validate () {
                const valid=this.$refs.form2.validate();
                if(this.newpassphrase === this.confirmpassphrase){
                    if(valid){
                        if(this.selectedFile!=null){
                            this.saveSignature(this.selectedFile);
                        }else{
                            this.saveSignature(null);
                        }
                    }else{
                        const data = { 
                            error: false,
                            message:"Passphrase do not match!",
                        }
                        displayErrorSuccessMessages(data);
                    }
                }
                
            },

            // set all pixels of the image to this color
            setCurrentColor(color) {
                const canvas = this.$refs.signatureCanvas;
                var context = canvas.getContext('2d');
                context.save();
                context.fillStyle = color;
                context.globalCompositeOperation = 'source-in';
                context.fillRect(0,0,canvas.width, canvas.height);
                context.restore();
            },

            initializeSignaturePad() {
                const canvas = this.$refs.signatureCanvas;
                this.signaturePad = new SignaturePad(canvas);
                canvas.addEventListener('mousedown', this.startDragging);
                canvas.addEventListener('mousemove', this.drag);
                canvas.addEventListener('mouseup', this.stopDragging);
            },

            saveSignature(file) {


                if(file!=null){
                    
                    const reader = new FileReader()
                    reader.readAsDataURL(this.selectedFile);


                    reader.onload = () => {
                        const base64 = reader.result.split(",")[1];
                        const signatureDto ={
                        fileName: "Signiture",
                        extension:"."+this.fileExtension,
                        base64Content:base64,
                        newPassphrase: this.confirmpassphrase,
                        }
                    
                        this.$store.dispatch('createUpdateSignature', { signatureDto });
                        router.push("/landing/dashboard");
                    }
                    reader.onerror = function(error) {
                        alert(error);
                    };
                }else{
                    var sigImg = this.signaturePad.toDataURL('image/png');
                    var index = sigImg.indexOf( "," )+1;
                    const sigImgB64 = sigImg.substring( index );
                    const signatureDto ={
                            base64Content:sigImgB64,
                            extension:".png",
                            fileName: "Signiture",
                            newPassphrase: this.confirmpassphrase,
                        }
                    this.$store.dispatch('createUpdateSignature', { signatureDto });
                    router.push("/landing/dashboard");
                }
            },

            resetSignature() {
                this.signaturePad.clear();
                this.signatureDataUrl = null;
                this.signaturePosition = { x: 100, y: 100 };
            },
            startDragging(event) {
                const { offsetX, offsetY } = event;
                if (
                    offsetX > this.signaturePosition.x &&
                    offsetX < this.signaturePosition.x + 100 &&
                    offsetY > this.signaturePosition.y &&
                    offsetY < this.signaturePosition.y + 50
                ) {
                    this.isDragging = true;
                    this.dragOffset = {
                    x: offsetX - this.signaturePosition.x,
                    y: offsetY - this.signaturePosition.y
                    };
                }
            },
            drag(event) {
                if (this.isDragging) {
                    const { offsetX, offsetY } = event;
                    this.signaturePosition.x = offsetX - this.dragOffset.x;
                    this.signaturePosition.y = offsetY - this.dragOffset.y;
                }
            },
            stopDragging() {
                this.isDragging = false;
                this.dragOffset = { x: 0, y: 0 };
            },
            SlideCarousel(value) {
                const carousel = this.$refs['carousel'];
                const currentPage = carousel.currentPage;
                const pageCount = carousel.pageCount;
                if (value == 'prev') {
                    currentPage != 0 ? carousel.goToPage(currentPage - 1) : carousel.goToPage(pageCount - 1);
                } else {
                    currentPage < pageCount - 1 ? carousel.goToPage(currentPage + 1) : carousel.goToPage(0);
                }
            },

            validatepassword () {
                if(this.newPassword===this.confirmPassword){
                    const passwordDto={
                        oldPassword: this.Password,
                        newPassword: this.confirmPassword
                    }

                    apolloClient.mutate({
                        mutation:changePassword,
                        variables:{
                            passwordDto
                        },
                    })
                    .then((response) => {
                        let output =response.data.changePassword;
                        
                        if(output.code == 5000){
                            const data = { 
                            error: true,
                            message:output.message 
                            }
                            displayErrorSuccessMessages(data);
                            const valid=this.$refs.form.validate();
                                if(valid){
                                this.step++;
                                this.initializeSignaturePad();
                                }
                        }
                        else {
                            const data = { 
                            error: false,
                            message:output.message,
                            code:output.code 
                            }
                            displayErrorSuccessMessages(data);
                        }
                    })
                }else{
                    const data = { 
                        error: false,
                        message:"Password do not match!",
                    }
                    displayErrorSuccessMessages(data);
                }

               
            },
        },
    }
</script>

<style>

#preview{
    height: 100px;
    width: 200px;
    background-color:gray;
    border-radius: 10px;
}

.content{
    height: 100vh;
    background-color: aliceblue;
}

.card{
    height: 50vh;
}

.btn_down{
    position: relative;
    top: 50px;
}

.services1 .icon-box {
  padding: 30px;
  position: relative;
  margin: 0 0 40px 0;
  background: #fff;
  box-shadow: 0 5px 26px 0 rgba(68, 88, 144, 0.14);
  transition: all 0.3s ease-in-out;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 10px;
  overflow: auto;
  width:100%;
}

.services1 .icon {
  margin: 0 auto 20px auto;
  padding-top: 17px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 72px;
  height: 72px;
}

.services1 .icon i {
  font-size: 36px;
  line-height: 1;
}

.services1 .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services1 .title a {
  color: #111;
}

.services1 .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.services1 .icon-box-pink .icon {
  background: #fceef3;
}

.services1 .icon-box-pink .icon i {
  color: #ff689b;
}

.services1 .icon-box-pink:hover {
  border-color: #ff689b;
}

.services1 .icon-box-cyan .icon {
  background: #e6fdfc;
}

.services1 .icon-box-cyan .icon i {
  color: #3fcdc7;
}

.services1 .icon-box-cyan:hover {
  border-color: #3fcdc7;
}

.services1 .icon-box-green .icon {
  background: #eafde7;
}

.services1 .icon-box-green .icon i {
  color: #41cf2e;
}

.services1 .icon-box-green:hover {
  border-color: #41cf2e;
}

.services1 .icon-box-blue .icon {
  background: #e1eeff;
}

.services1 .icon-box-blue .icon i {
  color: #2282ff;
}

.services1 .icon-box-blue:hover {
  border-color: #2282ff;
}

</style>