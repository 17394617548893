<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <v-navigation-drawer 
  dark
  v-model="drawer" 
  :mini-variant.sync="mini" 
  permanent app
  class="side-nav-style"
  >
    <v-list-item class="px-2">
      <v-list-item-avatar>
      </v-list-item-avatar>

      <v-list-item-title>e-Signiture</v-list-item-title>

      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense  class="active_list">
      <v-list-item
        v-for="item in items"
        v-if='hasPermissions(item.role)'
        :key="item.title"
        router
        :to="item.route"
        link
        class="tile"
      >
        <v-list-item-icon>
          <v-tool-tip>
            <v-icon>{{ item.icon }}</v-icon>
          </v-tool-tip>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-spacer></v-spacer>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["mini"],
  data() {
    return {
      drawer: false,
      //  mini:true,
      fav: true,
      menu: false,
      message: false,
      hints: true,
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          route: "/landing/dashboard",
          role:"ROLE_CREATE_DOCUMENT"
        },
        {
          title: "My Documents",
          icon: "mdi-file-sign ",
          route: "/documentslanding/documents",
          role:"ROLE_CREATE_DOCUMENT"
        },
        {
          title: "Shared Documents",
          icon: "mdi-folder-arrow-right",
          route: "/documentslanding/documents_shared",
          role:"ROLE_CREATE_DOCUMENT"
        },
        {
          title: "Received Documents",
          icon: "mdi-folder-arrow-left",
          route: "/documentslanding/documents_recieved",
          role:"ROLE_CREATE_DOCUMENT"
        }, 
        {
          title:"Institutions",
          icon:"mdi-office-building",
          route:"/Institutions/Institutionlist",
          role:"ROLE_VIEW_INSTITUTION",
        },
        {
          title: "User Management",
          icon: " mdi-account ",
          route: "/Admin/users",
          role:"ROLE_VIEW_USER",
        },
        // {
        //   title:"Settings",
        //   icon:"mdi-cog",
        //   route:"/settings",
        //   role:"ROLE_VIEW_INSTITUTION",
        // },
      ],
    };
  },
};
</script>

<style scoped>
.side-nav-style{
  background: #0c2e4c;/* fallback */
  /* background: linear-gradient(rgba(12, 46, 76,0.9), rgba(0, 115, 182, 0.6)),url("../../../../assets/images/mt_kilimanjaro.jpeg"); */
  background-size: cover;
  -webkit-transition:background-position 2s ease;
	-moz-transition:background-position 2s ease;
	-o-transition:background-position 2s ease;
	transition:background-position 2s ease;
}
.tile {
    margin: 5px;
    border-radius: 4px;
    color:whitesmoke;
  }
  .tile:hover {
    /* background: whitesmoke; */
    background:#0074da83;
  }
  .tile:active {
    /* background: yellow; */
    background: #00ccff86;
  }
  .active_list .v-list-group .v-list-item--active {
    color: #f4f4f4 !important;
  }
</style>




<!-- <v-list-group
:value="true"
prepend-icon="mdi-account-circle"
>
<template v-slot:activator>
  <v-list-item-title>Users</v-list-item-title>
</template>

  
    <v-list-item-content>
      <v-list-item-title>Admin</v-list-item-title>
    </v-list-item-content>
  
    <v-list-item-content>
      <v-list-item-title>Actions</v-list-item-title>
    </v-list-item-content>

</v-list-group> -->