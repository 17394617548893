<template>
<div class="body">
  <!-- nav -->
    <div class="nav pt-2">
      <div class="row">
        <div class="col-md-4 col-lg-2">
          <router-link to="/">
            <img src="../../../public/logo.png" alt="Logo" width="100px">
          </router-link>
        </div>
        <div class="col-md-8 col-lg-10">
          <v-col class="text-right">
            <router-link to="/">
              <v-btn
                color="#00529a"
                icon
                elevation="6"
                x-large
              >
                <v-icon>
                  mdi-home
                </v-icon>
              </v-btn>
            </router-link>
          </v-col>
        </div>
      </div> 
    </div>
  <!-- end nav -->
  <v-container fill-height fluid>
    <v-row justify="center" align="center" class="animate pr-5">
      <v-col cols="12">
        <v-window v-model="step">
          <v-window-item :value="1">
            <v-row justify="end">
              <v-col cols="4" >
                <div class="login_div text-center">
                  <img src="../../../public/esignature_logo.png" class="text-center" width="300px" alt="e-Signature">
                <!-- <h1 class="text-center">e-Signature</h1> -->
                <v-card-text>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field v-model="email" :rules="emailRules" label="Email" required outlined
                      class="rounded-lg" />

                    <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'" :rules="passwordRules" label="Password" outlined
                      class="rounded-lg" @click:append="show1 = !show1" v-on:keyup.enter="handleLogin" required />
                    <div class="text-center">
                      <div class="d-flex justify-space-between align-center">
                        <!-- <v-checkbox v-model="rememberMe" label="Remember Me" class=""></v-checkbox> -->

                        <a @click="step++" class="mb-1" color="primary" style="cursor: pointer">
                          Forgot Password?
                        </a>
                      </div>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-btn color="primary" @click="handleLogin" class="text-capitalize rounded-lg"
                            style="width:100px" dark>Login</v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </v-card-text>
                </div>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="2">
            <v-row class="fill-height1">
              <v-col class="10">

              </v-col>
              <v-col cols="2">
                <div class="text-center">
                  <h4 @click="step--" style="cursor: pointer" color="primart--text">
                    Sign in Again
                  </h4>
                </div>
                <v-card-text class="mt-8" rounded>
                  <v-form>
                    <v-text-field small dense v-model="email" label="user@gmail.com" outlined color="primary--text"
                      :rules="emailRules" />
                  </v-form>

                  <br /><br />
                  <div class="text-center mt-n5">
                    <v-btn class="no-uppercase" rounded color="primary" dark @click="handleSubmitEmail">Submit</v-btn>
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
  <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
      <div class="container">
        <div class="copyright">
          &copy; Copyright <strong><span>e-GA</span></strong>. All Rights Reserved
        </div>
        <div class="credits">
            Designed by e-Government Authority
        </div>
      </div>
    </footer>
</div>
</template>

<script>
import router from "../../router";
import axios from 'axios';
export default {
  data: () => ({
    step: 1,
    show1: false,
    rememberMe: false,
    valid: false,
    email: "",
    emailRules: [
      (v) => !!v || "User name is required",
      // (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    password: "",
    passwordRules: [(v) => !!v || "Password is required"],
    colors: [
      'indigo',
      'warning',
      'pink darken-2',
      'red lighten-1',
      'deep-purple accent-4',
    ],
    slides: [
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth',
    ],
  }),

  props: {
    source: String,
  },
  methods: {
    async handleLogin(e) {
      
      e.preventDefault(e);

      //
      const responce = await axios.post(
        "https://esignature.mikutano.co.tz/auth/login",
      //  " http://172.17.17.50:8060/auth/login",
        {
          email: this.email,
          password: this.password,
        }
        // { withCredentials: true }
      );

      if (responce.data.code === 5000) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${responce.data.token}`;


        // store token in store
        this.$store.commit('setTokenState', "Bearer " + responce?.data?.data?.token) ;
        router.push("/verify");
        // router.push("/landing/dashboard");
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: "Signed in successfully",
        });

        //take user detailes
        this.$store.dispatch('LoadUserDetails');
      } else {
        

        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          border: "50px",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          color: "danger",
          title: "Credential didn't match!",
        });
      }




    },
    handleSubmitEmail(e) {
      e.preventDefault();
      this.$refs.form.validate();
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "warning",
        title: "credential didn't match!",
      });
    },
  },
};
</script>
<style>
.body {
  background-image: url("../../../public/background1.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 90vh;
}

.fill-height1{
  padding-top: 130px;
  height: 80vh;
}


.login_div {
  /* background-color:  #00529a; */
  background-color: aliceblue;
  backdrop-filter: blur(5px);
  border: 10px;
  border-radius: 20px;
  
}
.no-uppercase {
  text-transform: unset !important;
}


@keyframes fade-in {
  0% {
      opacity: 0;
      transform: translateX(20px);
   }
   100% {
      opacity: 1;
      transform: translateX(0px);
   }
}
.animate {
     animation: fade-in 1.75s ;
}


</style>
