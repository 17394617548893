import getSignature from "../GraphQL/Documents/getSignature.graphql";
import { apolloClient } from "@/vue-apollo";
import { displayErrorSuccessMessages } from "../components/SharedComponent/Notification/notify.js";

export const initializedPageableParameter = {
  pageNumber: 1,
  size: 10,
  sortBy: 'id',
  sortDirection: 'DESC',
};


export default {
  state: {

    getSignature: [],
  },

  getters: {
    getSignature: (state) => state.getSignature,
  },

  mutations: {
    SetgetSignature(state, data) {
      state.getSignature = [];
      state.getSignature = data;
    },


  },

  actions: {
    async getSignature(context,passphrase){
      context.commit("setLoadingState", true);
      apolloClient.mutate({
        mutation:getSignature,
        variables:passphrase,
      })
      .then((response) => {
        let output =response.data.getSignature;
        console.log(output);
        if(output.code == 5000){
          //const Sigpath=output.data;
        // this.$store.commit('SetgetSignature',output.data);
        context.commit("SetgetSignature", output.data);
        localStorage.setItem("setSig",output.data)
          const data = { 
            error: true,
            message:output.message 
          }
          displayErrorSuccessMessages(data);
        }
        else {
          const data = { 
            error: false,
            message:output.message,
            code:output.code 
          }
          displayErrorSuccessMessages(data);
        }
      }).finally(()=>{context.commit("setLoadingState", false);})
      .catch((error)=>{
              context.commit("setLoadingState", false);
               console.error(error);
               const data = { 
                error: true,
                message:error 
              }
              displayErrorSuccessMessages(data);
              console.error("getDocuments ==>", error);
          });
    },

    }

};
