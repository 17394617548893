<template>
    <div></div>
</template>
<script>
import getUserProfile from "../../GraphQL/Users/UsersDetails.graphql";
import router from "../../router";
import { apolloClient } from "@/vue-apollo";
import { displayErrorSuccessMessages } from "../../components/SharedComponent/Notification/notify.js";
import store from "@/store/index.js";
export default {

    mounted(){
        this.checkuser();
    },

    methods:{
        async checkuser(){
            store.commit("setLoadingState", true);
                await apolloClient
                    .query({
                    fetchPolicy: "network-only",
                    query:getUserProfile,
                    variables: {
                    
                    },
                    })
                    .then((output) => {
                        const permissions=[];
                        
                        let data = output.data.getUserProfile;
                        
                        data.data.permissions.forEach(o =>{
                            permissions.push(o);
                        });
                        if(data.data.hasSignature){
                           
                            store.commit("setLoadingState", false);
                            router.push("/landing/dashboard");
                        }
                        else{
                            
                            store.commit("setLoadingState", false);
                            router.push("/welcome");
                        }
                    })
                    .catch((error) => {
                        store.commit("setLoadingState", false);
                        const data = { 
                            error: true,
                            message:error 
                        }
                        displayErrorSuccessMessages(data);
                        console.error("getUserDetails ==>", error);
                    });
            },

    }
}
</script>