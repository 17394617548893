<template>
    <v-container fluid>
        <ToolBar />
        <router-view />
    </v-container>
</template>
  
<script>
import ToolBar from "../SharedComponent/Navigation/ToolBar.vue"
export default {
    name: "DocumentsMain",
    components:{ToolBar}
};
</script>
  
<style scoped></style>