<template>
    <div class="body1">
        <!-- Nav -->
        <div class="nav pt-2">
            <div class="row">
            <div class="col-md-4 col-lg-2">
                <router-link to="/">
                    <img src="../../../public/logo.png" alt="Logo" width="100px">
                </router-link>
            </div>
            <div class="col-md-8 col-lg-10">
                <v-col class="text-right">
                    <router-link to="/">
                    <v-btn
                        color="#00529a"
                        icon
                        elevation="6"
                        x-large
                    >
                        <v-icon>
                        mdi-home
                        </v-icon>
                    </v-btn>
                </router-link>
                </v-col>
            </div>
            </div> 
        </div>
        <!-- / end nav -->

        <!-- body -->
        <v-container fill-height fluid>
            <v-row
                class="justify-center animate"
            >
                <v-col cols="6">
                    
                    <v-card
                        elevation="2"
                        outlined
                        tile
                        max-width="800px"
                    >
                        <v-card-title
                        >
                            <h3 class="text-center">CREATE AN ACCOUNT</h3>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-row>
                                    <v-col>
                                        <v-text-field 
                                            v-model="firstName" 
                                            label="First Name" 
                                            required 
                                            outlined
                                            class="rounded-lg" 
                                        />
                                    </v-col>
                                    <v-col>
                                        <v-text-field 
                                            v-model="middleName" 
                                            label="Middle Name" 
                                            required 
                                            outlined
                                            class="rounded-lg" 
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field 
                                            v-model="lastName" 
                                            label="Surname" 
                                            required 
                                            outlined
                                            class="rounded-lg" 
                                        />
                                    </v-col>
                                    <v-col>
                                        <v-text-field 
                                            v-model="email" 
                                            :rules="emailRules" 
                                            label="Email" 
                                            required 
                                            outlined
                                            class="rounded-lg" 
                                        />
                                    </v-col>
                                </v-row>    
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            label="Phone*"
                                            v-model="phone"
                                            placeholder="255"
                                            :rules="phonerules"
                                            outlined
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <router-link to="/login">
                                            <a  color="primary" style="cursor: pointer">
                                                Have already an account? Login here
                                            </a>
                                        </router-link>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-btn color="primary" @click="handleregister" class="text-capitalize rounded-lg mb-1 " style="width:100px" dark>Register</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>    
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!--End body -->
        <!-- Footer -->
        <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
            <div class="container">
                <div class="copyright">
                &copy; Copyright <strong><span>e-GA</span></strong>. All Rights Reserved
                </div>
                <div class="credits">
                    Designed by e-Government Authority
                </div>
            </div>
        </footer>
        <!-- End Footer -->
    </div>
</template>

<script>
import router from "../../router";
import axios from 'axios';
    export default {
        data(){
            return{
                firstName:'',
                middleName:'',
                lastName:'',
                email:'',
                phone:'',
                emailRules: [
                value => !!value || 'Required.',    
                (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'E-mail must be valid'
                ],
                phonerules: [
                    value => !!value || 'Required.',
                    (value) => value && value.length >= 12 || 'Please put a valid number',
                ],
            }
        },

        methods:{
            async handleregister() {
                //
                const responce = await axios.post(
                    "https://esignature.mikutano.co.tz/auth/register",
                    {
                    firstName: this.firstName,
                    middleName: this.middleName,
                    lastName: this.lastName,
                    phoneNumber: this.phone,
                    email: this.email,
                    }
                );

                if (responce.data.code === 5000) {
                    
                    
            
                    const Toast = this.$swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", this.$swal.stopTimer);
                        toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                    },
                    });
                    Toast.fire({
                    icon: "success",
                    title: "successfully registered",
                    });
                        router.push("/login");
                } else {

                    const Toast = this.$swal.mixin({
                    toast: true,
                    position: "top-end",
                    border: "50px",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", this.$swal.stopTimer);
                        toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                    },
                    });
                    Toast.fire({
                    icon: "warning",
                    color: "danger",
                    title: "Failed to register",
                    });
                }

            },
        },




    }
</script>

<style>
.body1{
		height:90vh;
        background-image: url("../../../public/background1.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
	}

    
@keyframes fade-in {
  0% {
      opacity: 0;
      transform: translateX(20px);
   }
   100% {
      opacity: 1;
      transform: translateX(0px);
   }
}

.animate {
     animation: fade-in 1.75s ;
}

</style>