import { render, staticRenderFns } from "./ModalIdle.vue?vue&type=template&id=3485c883&scoped=true&"
import script from "./ModalIdle.vue?vue&type=script&lang=js&"
export * from "./ModalIdle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3485c883",
  null
  
)

export default component.exports