<template>
    <v-app>
        <v-container fluid class="main-container">
          <div class="row ml-5 mt-5">
            <BackBtn />
          </div>
          &nbsp;
            <v-row>
              <v-col>
                <h2>
                    Documents  
                </h2>
              </v-col>
              <v-col  class="text-right">
                <AddDocumentdialog :dialog.sync="dialog"/>
              </v-col>
            </v-row>
            &nbsp;
            <!-- files display here -->
            <v-container fluid>
                  <hr>
                  &nbsp;
                <v-row dense>
                    <v-col>
                      <v-data-table
                          :headers="headers"
                          :items="userfile"
                          :items-per-page="5"
                          class="elevation-1"
                          :search="search"
                      >
                          <template v-slot:top>
                              <v-text-field
                              v-model="search"
                              label="Search"
                              class="mx-4"
                              ></v-text-field>
                          </template>
                          <template v-slot:item="row">
                              <tr>
                                <td>{{ row.index +1 }}</td>
                                <td>{{ row.item.title }}</td>
                                <td>{{ row.item.description }}</td>
                                <td>{{ row.item?.createdAt.split("T")[0]+" "+row.item?.createdAt.split("T")[1].split(".")[0] }}</td>
                                <td>
                                  <v-menu
                                    origin="center left"
                                    transition="scale-transition"
                                    open-on-hover
                                    top
                                    offset-y
                                  >
                                    <template v-slot:activator="{ on: menu, attrs }">
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                          <v-btn
                                            outlined
                                            color="#0c2e4c"
                                            dark
                                            v-bind="attrs"
                                            v-on="{ ...tooltip, ...menu }"
                                          >
                                            <v-icon dark>
                                              mdi-format-list-bulleted-square
                                            </v-icon>
                                          </v-btn>
                                        </template>
                                        <span>More</span>
                                      </v-tooltip>
                                    </template>
                                    <v-list dense>
                                      <v-list-item link @click="viewfile(row.item)">
                                          <v-icon>mdi-archive-eye</v-icon>
                                          &nbsp;
                                          <v-list-item-title>View</v-list-item-title>
                                      </v-list-item> 
                                      <v-list-item link  @click="openShareModal(row.item.uuid)">
                                          <v-icon>mdi-share-variant</v-icon>
                                          &nbsp;
                                          <v-list-item-title  >Share</v-list-item-title>
                                      </v-list-item>
                                      <v-list-item  @click="deleteFile(row.item.uuid)" link>
                                          <v-icon>mdi-delete</v-icon>
                                          &nbsp;
                                          <v-list-item-title >Delete</v-list-item-title>
                                      </v-list-item>
                                  </v-list>
                                  </v-menu>
                                </td>
                              </tr>
                          </template>
                      </v-data-table>
                    </v-col>
                </v-row>
            </v-container>        
          <ShareDocument v-if="dialog" :dialog="dialog" :Docuuid="uuid" @close="closeModal"></ShareDocument>
          <v-tour name="Documents" :steps="steps"></v-tour>
        </v-container>
    </v-app>
</template>


<script>
import router from '@/router';
import { mapGetters } from 'vuex';
import AddDocumentdialog from '@/components/Documents/AddDocument.vue';
import ShareDocument from './ShareDocument.vue';
import BackBtn from "../SharedComponent/Button/BackBtn.vue";
export default{
    name: "Documents",
    data(){
      return{
        title:'',
        description:'',
        dialog:false,
        showModal:false,
        uuid:"",
        search: '',
        headers: [
          { text: 'No',value: 'id'},
          { text: 'Title',value: 'title'},
          { text: 'Description', value: 'description' },
          { text: 'Uploaded At', value: 'createdAt' },
          { text: 'Actions', value: 'action' },
        ],
        steps: [
          {
            target: '#v-step-0', 
            header: {
              title: 'Get Started',
            },
            content: `Add Document`,
            params: {
              placement: 'top' 
            }
          },
          {
            target: '#v-step-1',
            content: 'By clicking here you can add signature to your document',
            params: {
              placement: 'top'
            }
          },
          {
            target: '#v-step-2',
            content: 'You can share your documents here.',
            params: {
              placement: 'top'
            }
          }
        ]
      }
    },
    methods:{
      openShareModal(uuid){
        this.uuid=uuid;
        this.dialog = true;
      },

      closeModal() {
        this.dialog = false;
      },

      //view file
      viewfile(data){
        this.$store.commit('setDocuuid',data.uuid);
        this.$store.commit('setDoctitle',data.title);
        this.$store.commit('setDocdescription',data.description);
        this.$store.commit('setData', data.filePath);
        router.push({path:'/viewpdf'});
      },

      //delete file
      deleteFile(uuid){
        console.log(uuid);
        this.$store.dispatch('deleteDocuments', { uuid })
      },

    },

    components: {
      AddDocumentdialog,
      ShareDocument,
      BackBtn 
    },

    
    computed:{
      ...mapGetters(['getmyDocuments']),
        userfile()
        {
         
          return this.getmyDocuments
        },
    },
    mounted(){
      this.$store.dispatch('LoadDocuments');
      // this.$tours['Documents'].start()
    },

   

  


}  


    

   
</script>



<style>

</style>

