<template>
    <v-app>
        <v-container fluid>
            <v-col class="ml-5">
                <BackBtn />
            </v-col>
            <v-row align="end"
            justify="end"
            > 
                <v-btn
                dark
                color="primary"
                @click="openModal()"
                >
                    Change Password
                </v-btn> 
            </v-row>
            &nbsp;
            <v-row>
                <v-col col-12>
                    <v-card>
                        <v-row align="center"
                            justify="center">
                            <v-col col-4>
                                <div class="profile_pic">
                                </div>
                            </v-col>
                            <v-col col-8>
                                <div>
                                    <p>name:{{ userdetails.firstName }}</p>
                                    <p>surname:{{ userdetails.lastName }}</p>
                                    <p>email:{{ userdetails.email }}</p>
                                    <p>phone</p>
                                    <p>institution</p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <Passwordreset v-if="showModal" :dialog="showModal"  @close="closeModal" />
    </v-app>
</template>

<script>
import BackBtn from "../SharedComponent/Button/BackBtn.vue"
import Passwordreset from '@/components/Profile/password_reset.vue';
import { mapGetters } from 'vuex';
    export default {
        data () {
      return {
        uuid:null,
        dialog:false,
        showModal:false,
        data:null,
      }
    },

        components:{
            Passwordreset,
            BackBtn,
            // edituser
        },

        computed:{
            ...mapGetters(['getUserDetails']),
                userdetails()
                {
                return this.getUserDetails;
                },
        },

        mounted(){
            this.$store.dispatch('LoadUserDetails');
        },
        
        methods:{
            openModal() {
                this.showModal = true;
            },

            closeModal() {
                this.showModal = false;
                this.dialog=false;
            },
        },

    }
</script>

<style>
.profile_pic{
        background-color: white;
        width: 400px;
        height: 400px;
        padding: 10px;
        border: 1px solid #0c2e4c;
        border-radius: 100%;
        box-shadow: 10px;
    }
</style>