import getUserProfile from "../GraphQL/Users/UsersDetails.graphql";
import { apolloClient } from "@/vue-apollo";
import { displayErrorSuccessMessages } from "../components/SharedComponent/Notification/notify.js";



export default {
  state: {

    getUserDetails: [],
  },

  getters: {
    getUserDetails: (state) => state.getUserDetails,
  },

  mutations: {
    SetgetUserDetails(state, data) {
      state.getUserDetails = [];
      state.getUserDetails = data;
    },


  },

  actions: {
    
    LoadUserDetails: async (context) => {
      context.commit("setLoadingState", true);
      await apolloClient
        .query({
          fetchPolicy: "network-only",
          query:getUserProfile,
          variables: {
          
          },
        })
        .then((output) => {
            const permissions=[];
            let data = output.data.getUserProfile;
            data.data.permissions.forEach(o =>{
                permissions.push(o);
            });
            context.commit("setUserPerms",permissions);
            context.commit("SetgetUserDetails", data.data);
            context.commit("setLoadingState", false);
        })
        .catch((error) => {
          context.commit("setLoadingState", false);
          const data = { 
            error: true,
            message:error 
          }
          displayErrorSuccessMessages(data);
          console.error("getUserDetails ==>", error);
        });
    },

}

};
