<template>
    <v-app>
        <v-container fluid class="main-container">
            <div class="row ml-5 mt-5">
              <BackBtn />
            </div>
            &nbsp;
            <v-row dense>
                <h2>
                    Document Details
                </h2>
            </v-row>
            <v-row>
              <v-col class="text-right">
              
                <hr>
              </v-col>
            </v-row>
            &nbsp;
            <!-- files display here -->
            
            <v-container fluid>
                <v-row dense>                   
                    <v-col cols="4">
                      <v-card
                        elevation="3"
                        outlined
                        shaped
                      >
                        <v-card-title>
                          Current File Holder
                        </v-card-title>
                        <v-card-text>
                          <p>Name: {{ userfile2.fullName }}</p>
                       
                          <p>Phone: {{ userfile2.phone }}</p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                          :headers="headers"
                          :items="userfile"
                          :items-per-page="5"
                          class="elevation-1"
                      >
                          <template v-slot:top>
                              <v-text-field
                              v-model="search"
                              label="Search"
                              class="mx-4"
                              ></v-text-field>
                          </template>
                          <template v-slot:item="row">
                              <tr>
                                <td>{{ row.item.fullName }}</td>
                                <td>{{ row.item.signed?"Signed":"Pending" }}</td>
                                <td>{{ row.item?.signedAt === null ? "-" : row.item?.signedAt.split("T")[0]+" "+row.item?.signedAt.split("T")[1].split(".")[0]}}</td>
                                <td>{{ row.item?.receivedAt === null ? "-" : row.item?.receivedAt.split("T")[0]+" "+row.item?.receivedAt.split("T")[1].split(".")[0]}}</td>
                              </tr>
                          </template>
                      </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="dialog"
            >
              <template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Are you sure?</span>
                  </v-card-title>
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-container>
                      <v-text-field
                        v-model="Reason"
                        label="Reason"
                        :rules="rules"
                        hide-details="auto"
                      ></v-text-field>
                      </v-container>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                            <v-btn
                              color="red darken-1"
                              text
                              @click="closeModal"
                            >
                              Close
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="RejectMutation"
                            >
                              Reject
                            </v-btn>
                          </v-card-actions>
                  </v-form>
                </v-card>
              </template>
            </v-dialog>
        </v-container>
    </v-app>
   
</template>


<script>

import { mapGetters } from 'vuex';
import BackBtn from "../SharedComponent/Button/BackBtn.vue";
export default{

    name: "Documents",
   
    data(){
      return{
        headers: [
          { text: 'Name',value: 'fullname',},
          { text: 'Status', value: 'description' },
          { text: 'Signed At', value: 'signedAt' },
          { text: 'Recieved At', value: 'recievedAt' },
         
        ],
      }
    },
    methods:{
     
    },

    components: {
      BackBtn
    },

    
    computed:{
      ...mapGetters(['getSharedDocumentDetails']),
        userfile()
        {
          //this.$store.actions. LoadDocuments
          return this.getSharedDocumentDetails
        },
         ...mapGetters(['getCurrentUser']),
        userfile2(){
          return this.getCurrentUser
        }
    },

    mounted(){
      this.$store.dispatch('getSharedDocumentDetails',this.$store.state.docuuid);
    },

   

  


}  


    

   
</script>



<style>

</style>

