<template>
    <v-row justify="center">
      <v-col class="text-right">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <v-card>
            <v-card-title>
                <span class="text-h5">Change Password</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Password*"
                                v-model="Password"
                                :rules="rules"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="New Password*"
                                v-model="newPassword"
                                :rules="rules"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Confirm Password*"
                                v-model="confirmPassword"
                                :rules="confirmrules"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                    <small>*indicates required field</small>
            </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="closeModal"
            >
              Close
            </v-btn>
            <v-btn
              color="green"
              text
              @click="onSubmit"
            >
              Save
            </v-btn>
          </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
       
    </v-row>   
  </template>
  
  
  
  <script>
  import changePassword from '../../GraphQL/Auth/passwordChange.graphql';
  import { apolloClient } from "@/vue-apollo";
  import { displayErrorSuccessMessages } from "../../components/SharedComponent/Notification/notify.js";
  export default {
    props: {
     dialog:Boolean,
     type:String,
     data:{
        type: Object,
        required: true,
        
      },
    },

    data(){
        return{
            Password:"",
            newPassword:"",
            confirmPassword:"",
            rules: [
                  value => !!value || 'Required.',
                  (value) => value && value.length >= 8 || 'Password must be at least 8 characters',
                  (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) || 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number',
                ],
            confirmrules:[
                  value => !!value || 'Required.',
                  (value) => value && value.length >= 8 || 'Password must be at least 8 characters',
                  (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) || 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number',
                  (value) =>value === this.newPassword || 'Passwords do not match', 
            ]    
        }
    },
  
    mounted(){
      this.firstName=this.data.firstName;
      this.middleName=this.data.middleName;
      this.lastName=this.data.lastName;
      this.email=this.data.username;
      this.phone=this.data.phone;
      this.roleUuids=this.data.roles[0].uuid;
      this.institutionUuid=this.data.institution?.uuid;
  
    },
  
    name: "Documents",
  

    methods:{
      closeModal() {
        this.dialog = false;
        this.$emit('close'); // Emit the 'close' event to the parent component
      },
    
      onSubmit() {
        const passwordDto={
          oldPassword: this.Password,
          newPassword: this.confirmPassword
        }


        apolloClient.mutate({
          mutation:changePassword,
          variables:{
            passwordDto
          },
        })
        .then((response) => {
          let output =response.data.changePassword;
          console.log(output);
          if(output.code == 5000){
            const data = { 
              error: true,
              message:output.message 
            }
            displayErrorSuccessMessages(data);
          }
          else {
            const data = { 
              error: false,
              message:output.message,
              code:output.code 
            }
            displayErrorSuccessMessages(data);
          }
        })
      },
    },
  
    components: { 
    },
  
  
  };
  </script>
  
  <style>
  
  </style>
  