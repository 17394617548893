<template>
  <v-row justify="center">
    <v-col class="text-right">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
      >
          <v-card>
          <v-card-title>
              <span class="text-h5">{{ type }}User</span>
          </v-card-title>
          <v-card-text>
              <v-container>
                  <v-row>
                      <v-col cols="6">
                          <v-text-field
                              label="First Name*"
                              v-model="firstName"
                              required
                          ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                          <v-text-field
                              label="Middle Name*"
                              v-model="middleName"
                              required
                          ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                          <v-text-field
                              label="Last Name*"
                              v-model="lastName"
                              required
                          ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                          <v-text-field
                              label="Email*"
                              v-model="email"
                              :rules="emailRules"
                              required
                          ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                          <v-text-field
                              label="Phone*"
                              v-model="phone"
                              placeholder="255"
                              :rules="phonerules"
                              required
                          ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                          <v-select
                              :items="institutions"
                              item-text="name"
                              item-value="uuid"
                              label="Institution*"
                              v-model="institutionUuid"
                              required
                          ></v-select>
                      </v-col>
                      <v-col cols="6">
                          <v-select
                              :items="roles"
                              item-text="name"
                              item-value="uuid"
                              label="Role*"
                              v-model="roleUuids"
                              required
                          ></v-select>
                      </v-col>
                  </v-row>
              </v-container>
                  <small>*indicates required field</small>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="closeModal"
          >
            Close
          </v-btn>
          <v-btn
            color="green"
            text
            @click="onSubmit"
          >
            Save
          </v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
     
  </v-row>   
</template>



<script>
import { mapGetters } from 'vuex';
export default {
  props: {
   dialog:Boolean,
   type:String,
   data:{
      type: Object,
      required: true,
    },
  },

  mounted(){
    this.$store.dispatch('LoadRoles');
    this.$store.dispatch('LoadInstitutions');
    this.firstName=this.data.firstName;
    this.middleName=this.data.middleName;
    this.lastName=this.data.lastName;
    this.email=this.data.username;
    this.phone=this.data.phone;
    this.roleUuids=this.data.roles[0].uuid;
    this.institutionUuid=this.data.institution?.uuid;

  },

  name: "Documents",

  data(){
  return{
      firstName:'',
      middleName:'',
      lastName:'',
      email:'',
      phone:'',
      roleUuids:'',
      institutionUuid:'',
      emailRules: [
      value => !!value || 'Required.',    
      (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'E-mail must be valid'
      ],
      phonerules: [
          value => !!value || 'Required.',
          (value) => value && value.length >= 12 || 'Please put a valid number',
      ],
  }
  },
  methods:{
  closeModal() {
      this.dialog = false;
      this.$emit('close'); // Emit the 'close' event to the parent component
  },
  
  onSubmit() {
    if(this.data){
      const  user ={
          uuid:this.data.uuid,
          firstName:this.firstName,
          middleName:this.middleName,
          lastName:this.lastName,
          email:this.email,
          phone:this.phone,
          institutionUuid:this.institutionUuid,
          roleUuids:this.roleUuids,
      }
      this.$store.dispatch('createUpdateUser', { user });
      this.dialog = false;
      this.$emit('close'); 
    }
    else{

         const  user ={
          firstName:this.firstName,
          middleName:this.middleName,
          lastName:this.lastName,
          email:this.email,
          phone:this.phone,
          institutionUuid:this.institutionUuid,
          roleUuids:this.roleUuids,
      }
      this.$store.dispatch('createUpdateUser', { user });
      this.dialog = false;
      this.$emit('close'); 
    }
   
  },
  },

  components: { 
  },

  
  computed:{
  ...mapGetters(['getRoles']),
  ...mapGetters(['getInstitutions']),
      roles()
      {
      return this.getRoles;
      },
      institutions(){
       
      return this.getInstitutions;
      }
  },

};
</script>

<style>

</style>
