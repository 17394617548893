<template>
    <v-app>
        <v-container fluid class="main-container">
            <div class="row ml-5 mt-5">
              <BackBtn />
            </div>
            &nbsp;
            <v-row dense>
                <h2>
                  Received Documents
                </h2>
            </v-row>
            &nbsp;
            <hr>
            &nbsp;
            <v-card>
                <template>
                  <v-tabs
                    v-model="tab"
                    align-with-title
                    background-color="primary"
                    dark
                  >
                    <v-tab
                      href="#tab1"
                    >
                      UnSigned
                    </v-tab>
                    <v-tab
                      href="#tab2"
                    >
                      Signed
                    </v-tab>
                  </v-tabs>
                </template>
              <v-tabs-items v-model="tab">
                <v-tab-item
                id="tab1"
                >
                    <v-container fluid>
                      <v-row dense>
                        <v-col>
                            <v-data-table
                                :headers="headers"
                                :items="UnsignedRecievedDocument.content"
                                :items-per-page="5"
                                :search="search"
                            >
                                <template v-slot:top>
                                    <v-text-field
                                    v-model="search"
                                    label="Search"
                                    class="mx-4"
                                    ></v-text-field>
                                </template>
                                <template  v-slot:item="row">
                                    <tr>
                                      <td>{{ row.index +1 }}</td>
                                      <td>{{ row.item.title }}</td>
                                      <td>{{ row.item.description }}</td>
                                      <td>{{ row.item.userAccount.fullName }}</td>
                                      <td>
                                        <v-menu
                                          origin="center left"
                                          transition="scale-transition"
                                          open-on-hover
                                          top
                                          offset-y
                                        >
                                          <template v-slot:activator="{ on: menu, attrs }">
                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on: tooltip }">
                                                <v-btn
                                                  outlined
                                                  color="#0c2e4c"
                                                  dark
                                                  v-bind="attrs"
                                                  v-on="{ ...tooltip, ...menu }"
                                                >
                                                  <v-icon dark>
                                                    mdi-format-list-bulleted-square
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>More</span>
                                            </v-tooltip>
                                          </template>
                                          <v-list dense>
                                            <v-list-item link @click="viewfile(row.item)">
                                                <v-icon>mdi-archive-eye</v-icon>
                                                &nbsp;
                                                <v-list-item-title>View</v-list-item-title>
                                            </v-list-item> 
                                            <v-list-item   @click="Rejectfile(row.item.uuid)" link>
                                                <v-icon>mdi-cancel</v-icon>
                                                &nbsp;
                                                <v-list-item-title >Reject</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        </v-menu>
                                      </td>
                                    </tr>
                                </template>
                            </v-data-table>
                          </v-col>
                      </v-row>
                  </v-container>
                  <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600"
                    v-model="dialog"
                  >
                    <template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Are you sure?</span>
                        </v-card-title>
                        <v-form
                          ref="form"
                          lazy-validation
                        >
                          <v-container>
                            <v-text-field
                              v-model="Reason"
                              label="Reason"
                              hide-details="auto"
                            ></v-text-field>
                            </v-container>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                  <v-btn
                                    color="red darken-1"
                                    text
                                    @click="closeModal"
                                  >
                                    Close
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="RejectMutation"
                                  >
                                    Reject
                                  </v-btn>
                                </v-card-actions>
                        </v-form>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-tab-item>
                <v-tab-item
                  id="tab2"
                >
                <v-container fluid>
                      <v-row dense>
                        <v-col>
                            <v-data-table
                                :headers="headers"
                                :items="signedRecievedDocument.content"
                                :items-per-page="5"
                                :search="search"
                            >
                                <template v-slot:top>
                                    <v-text-field
                                    v-model="search"
                                    label="Search"
                                    class="mx-4"
                                    ></v-text-field>
                                </template>
                                <template v-slot:item="row">
                                  <tr>
                                      <td>{{ row.index +1 }}</td>
                                      <td>{{ row.item.title }}</td>
                                      <td>{{ row.item.description }}</td>
                                      <td>{{ row.item.userAccount.fullName }}</td>
                                      <td>
                                        <v-menu
                                          origin="center left"
                                          transition="scale-transition"
                                          open-on-hover
                                          top
                                          offset-y
                                        >
                                          <template v-slot:activator="{ on: menu, attrs }">
                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on: tooltip }">
                                                <v-btn
                                                  outlined
                                                  color="#0c2e4c"
                                                  dark
                                                  v-bind="attrs"
                                                  v-on="{ ...tooltip, ...menu }"
                                                >
                                                  <v-icon dark>
                                                    mdi-format-list-bulleted-square
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>More</span>
                                            </v-tooltip>
                                          </template>
                                          <v-list dense>
                                            <v-list-item link @click="viewfile(row.item)">
                                                <v-icon>mdi-archive-eye</v-icon>
                                                &nbsp;
                                                <v-list-item-title>View</v-list-item-title>
                                            </v-list-item> 
                                            <!-- <v-list-item   @click="Rejectfile(row.item.uuid)" link>
                                                <v-icon>mdi-cancel</v-icon>
                                                &nbsp;
                                                <v-list-item-title >Reject</v-list-item-title>
                                            </v-list-item> -->
                                        </v-list>
                                        </v-menu>
                                      </td>
                                    </tr>
                                </template>
                            </v-data-table>
                          </v-col>
                      </v-row>
                  </v-container>
                  <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600"
                    v-model="dialog"
                  >
                    <template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Are you sure?</span>
                        </v-card-title>
                        <v-form
                          ref="form"
                          lazy-validation
                        >
                          <v-container>
                            <v-text-field
                              v-model="Reason"
                              label="Reason"
                              hide-details="auto"
                            ></v-text-field>
                            </v-container>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                  <v-btn
                                    color="red darken-1"
                                    text
                                    @click="closeModal"
                                  >
                                    Close
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="RejectMutation"
                                  >
                                    Reject
                                  </v-btn>
                                </v-card-actions>
                        </v-form>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
            &nbsp;
            <!-- files display here -->
            
        </v-container>
    </v-app>
   
</template>


<script>
import router from '@/router';
import { mapGetters } from 'vuex';
import { apolloClient } from "@/vue-apollo";
import { displayErrorSuccessMessages } from "../../components/SharedComponent/Notification/notify.js";
import rejectSharedDoc from "../../GraphQL/Documents/rejectSheredDocument.graphql"
import BackBtn from "../SharedComponent/Button/BackBtn.vue";
export default{
    name: "Documents",
    data(){
      return{
        title:'',
        description:'',
        dialog:false,
        showModal:false,
        uuid:"",
        Reason:'',
        search:'',
        tab:"tab1",
        headers: [
          { text: 'No',value: 'id'},
          { text: 'Title',value: 'title',},
          { text: 'Description', value: 'description' },
          { text: 'Recieved from', value: 'createdBy' },
          { text: 'Actions', value: 'action' },
        ],
      }
    },
    methods:{
     
      closeModal() {
        this.dialog = false;
      },

      //view file
      viewfile(data){
        this.$store.commit('setDocuuid',data.uuid);
        this.$store.commit('setDoctitle',data.title);
        this.$store.commit('setDocdescription',data.description);
        this.$store.commit('setData', data.filePath);
        router.push({path:'/viewshared'});
      },

      //delete file
      Rejectfile(uuid){
        this.uuid=uuid;
        this.dialog=true;
      },

      RejectMutation(){

        const docRejDto ={
          message: this.Reason,
          uuid: this.uuid
        }

        apolloClient.mutate({
          mutation:rejectSharedDoc,
          variables:{
            docRejDto
          },
        })
        .then((response) => {
          let output =response.data.rejectSharedDoc;
          console.log(output);
          if(output.code == 5000){
            const data = { 
              error: true,
              message:output.message 
            }
            displayErrorSuccessMessages(data);
          }
          else {
            const data = { 
              error: false,
              message:output.message,
              code:output.code 
            }
            displayErrorSuccessMessages(data);
          }
        })
        .catch((error)=>{
                console.error(error);
                const data = { 
                  error: true,
                  message:error 
                }
                displayErrorSuccessMessages(data);
                console.error("getDocuments ==>", error);
        });
      },
    },

    components: {
      BackBtn 
    },

    computed:{
      ...mapGetters(['getSignedRecievedDocument']),
      ...mapGetters(['getUnSignedRecievedDocument']),

        signedRecievedDocument()
        {
          return this.getSignedRecievedDocument
        },

        UnsignedRecievedDocument()
        {
          return this.getUnSignedRecievedDocument
        },
    },
    mounted(){
      // this.$store.dispatch('LoadSharedDocument');
      this.$store.dispatch('LoadSignedRecievedDocument');
      this.$store.dispatch('LoadUnSignedRecievedDocument');
      const lastActiveTab = localStorage.getItem("tab");
      this.tab = lastActiveTab || "tab1";
    },
}  
</script>
