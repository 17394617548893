<template>
  <v-container>
    <v-app-bar dense app color="#0c2e4c" dark>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon @click="mini = !mini"></v-app-bar-nav-icon>
      </div>
      <v-spacer></v-spacer>
     <div class="ml-2">
     </div>
     <!-- <v-btn
      icon
     
      @click="alert = true"
     >
        <v-badge
          :content="messages"
          :value="messages"
          color="#E53935"
          overlap
        >
          <v-icon>
            mdi-bell
          </v-icon>
        </v-badge>
     </v-btn> -->
     
      <v-menu dense flat offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn plain color="white" dark v-bind="attrs" v-on="on">
            <span color="white">{{ userdetails.email }}</span>
            <v-icon right color="grey"> mdi-menu-down </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            router
            :to="item.route"
            link
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- <v-alert
      v-model="alert"
      dismissible
      color="#0c2e4c"
      border="left"
      elevation="2"
      colored-border
      icon="mdi-bell"
    >
      You've got <strong>7</strong> new shared documents!.
    </v-alert> -->
    <SideBar :mini="mini" />
  </v-container>
</template>

<script>
import SideBar from "./SideBar.vue";
import { mapGetters } from 'vuex';
export default {
  components: { SideBar },
  data() {
    return {
      mini: false,
      fav: true,
      menu: false,
      message: false,
      hints: true,
      messages:7,
      alert:false,
      items: [
        { icon: "mdi-account-tie", title: "My Profile", route: "/profile/profile_page" },
        { icon: "mdi-draw-pen", title: "Signature", route: "signiture" },
        { icon: "mdi-power", title: "Logout", route: "/" },
      ],
    };
  },
  computed:{
      ...mapGetters(['getUserDetails']),
        userdetails()
        {
          return this.getUserDetails;
        },
    },
    mounted(){
      this.$store.dispatch('LoadUserDetails');
    },
};
</script>
<style>
v-btn:hover:before {
  background-color: transparent;
}
</style>