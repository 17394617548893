import getAllUsers from "../../GraphQL/Admin/Users.graphql";
import createUpdateUser from "../../GraphQL/Admin/Users/createUpdateUser.graphql";
import deleteUserByUuid from "../../GraphQL/Admin/Users/DeleteUser.graphql";
import { displayErrorSuccessMessages } from "../../components/SharedComponent/Notification/notify";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
      getUsers: [],
    },
  
    getters: {
      getUsers: (state) => state.getUsers,
    },
  
    mutations: {

      loadUsers(state, data) {
        if (state.getUsers.find(({ uuid }) => uuid === data.uuid)) {
          state.getUsers.find(({ uuid }) => uuid === data.uuid).firstName = data.firstName;
          state.getUsers.find(({ uuid }) => uuid === data.uuid).middleName = data.middleName;
          state.getUsers.find(({ uuid }) => uuid === data.uuid).lastName = data.lastName;
          state.getUsers.find(({ uuid }) => uuid === data.uuid).username = data.username;
          state.getUsers.find(({ uuid }) => uuid === data.uuid).phone = data.phone;
          state.getUsers.find(({ uuid }) => uuid === data.uuid).institution = data.institution;
          state.getUsers.find(({ uuid }) => uuid === data.uuid).roles = data.roles;
        } else {
          state.getUsers.push(data);
        }
      },
      clearUsers(state) {
        state.getUsers = [];
      },
      deleteUsers(state, data) {
        state.getUsers.splice(
          state.getUsers.findIndex(({ uuid }) => uuid === data),
          1
        );
      },

  
    },
  
    actions: {
      
      LoadUsers: async (context) => {
        context.commit("clearUsers");
        context.commit("setLoadingState", true);
        await apolloClient
          .query({
            fetchPolicy: "network-only",
            query: getAllUsers,
            variables: {
            
            },
          })
          .then((output) => {
            output.data.getAllUsers.content?.forEach((element) => {
              console.log(element.id)
              let data={
                id:element.id,
                uuid:element.uuid,
                firstName:element.firstName,
                middleName:element.middleName,
                lastName:element.lastName,
                username:element.username,
                phone:element.phone,
                institution:element.institution,
                roles:element.roles,
              }
              context.commit("loadUsers", data);
            });
          }).finally(()=>{context.commit("setLoadingState", false);})
          .catch((error) => {
            context.commit("setLoadingState", false);
            const data = { 
              error: true,
              message:error 
            }
            displayErrorSuccessMessages(data);
            console.error("getUsers ==>", error);
          });
      },

      async createUpdateUser(context,UserAccountDtoInput){
        apolloClient.mutate({
          mutation:createUpdateUser,
          variables:UserAccountDtoInput,
        })
        .then((response) => {
          let output =response.data.createUpdateUser;
          console.log(output.data);
          if(output.code == 5000){

            let tempdata={
              id:output.data.id,
              uuid:output.data.uuid,
              firstName:output.data.firstName,
              middleName:output.data.middleName,
              lastName:output.data.lastName,
              username:output.data.username,
              phone:output.data.phone,
              institution:output.data.institution,
              roles:output.data.roles,
            }

            context.commit("loadUsers",tempdata);
            context.commit("setLoadingState", false);
            const data = { 
              error: true,
              message:output.message 
            }
            displayErrorSuccessMessages(data);
          }
          const data = { 
            error: true,
            message:output.message 
          }
          displayErrorSuccessMessages(data);
        })
        .catch((error)=>{
                 console.error(error);
            });
      },

      async  DeleteUsers(context,uuid){
        apolloClient.mutate({
          mutation:deleteUserByUuid,
          variables: {
            uuid:uuid
            },
        })
        .then((response) => {
          let output =response.data.deleteUserByUuid;
          console.log(output);
          if(output.code == 5000){
            
            context.commit("deleteUsers", uuid);
            const data = { 
              error: true,
              message:output.message 
            }
            displayErrorSuccessMessages(data);
          }
          const data = { 
            error: true,
            message:output.message 
          }
          displayErrorSuccessMessages(data);
        })
        .catch((error)=>{
                 console.error(error);
            });
      },
  
     

  }
  
};