import Vue from "vue";

export function displayErrorSuccessMessages(data) {
    console.log(data);
    if (data.error) {
      Vue.swal({
        toast: true,
        position: "top",
        title: "Success!",
        text: data.message,
        timerProgressBar: true,
        timer: 2000,
        icon: "success",
        showConfirmButton: false,
        animation: true,
      });
    } else {
      Vue.swal({
        toast: true,
        position: "top",
        title: "Error: " + data.code + "!",
        text: data.message,
        timerProgressBar: true,
        timer: 2000,
        icon: "error",
        showConfirmButton: false,
        animation: true,
      });
    }
  }





