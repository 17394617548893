import getDashboard from "../GraphQL/Users/getDashboard.graphql";
import { apolloClient } from "@/vue-apollo";
import { displayErrorSuccessMessages } from "../components/SharedComponent/Notification/notify.js";

export const initializedPageableParameter = {
  pageNumber: 1,
  size: 10,
  sortBy: 'id',
  sortDirection: 'DESC',
};


export default {
  state: {

    getDashboard: [],
  },

  getters: {
    getDashboard: (state) => state.getDashboard,
  },

  mutations: {
    SetgetDashboard(state, data) {
      state.getDashboard = [];
      state.getDashboard = data;
    },


  },

  actions: {
    async getDashboard(context){
      context.commit("setLoadingState", true);
      apolloClient.mutate({
        mutation:getDashboard,
      })
      .then((response) => {
        let output =response.data.getDashboardDetails;
        console.log(output);
        if(output.code == 5000){
            console.log(output.data);
        context.commit("SetgetDashboard", output.data);
          const data = { 
            error: true,
            message:output.message 
          }
          displayErrorSuccessMessages(data);
        }
        else {
          const data = { 
            error: false,
            message:output.message,
            code:output.code 
          }
          displayErrorSuccessMessages(data);
        }
      }).finally(()=>{context.commit("setLoadingState", false);})
      .catch((error)=>{
              context.commit("setLoadingState", false);
               console.error(error);
               const data = { 
                error: true,
                message:error 
              }
              displayErrorSuccessMessages(data);
              console.error("getDocuments ==>", error);
          });
    },

    }

};
