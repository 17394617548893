<template>
  <div>
      <!-- add roles -->
      <v-row>
          <v-col class="text-right">
              <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="600px"
              >
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          outlined
                          color="primary"
                          rounded
                          link
                          class="text-capitalize mt-4 ml-5"
                          v-bind="attrs"
                          v-on="on"
                      >
                          Add Role
                      </v-btn>
                  </template>
                  <v-card>
                      <v-card-title>
                      <span class="text-h5">Add Role</span>
                      </v-card-title>
                      <v-card-text>
                      <v-container>
                          <v-row>
                          <v-col cols="6">
                              <v-text-field
                              label="RoleName*"
                              v-model="roleName"
                              required
                              ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                              <v-text-field
                              label="DisplayName*"
                              v-model="displayName"
                              required
                              ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                              <v-select
                              :items="perm"
                              item-text="name"
                              item-value="uuid"
                              label="Permission*"
                              v-model="PermUuid"
                              multiple
                              persistent-hint
                              required
                              ></v-select>
                          </v-col>
                          </v-row>
                      </v-container>
                      <small>*indicates required field</small>
                      </v-card-text>
                      <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="red"
                          text
                          @click="closeModal"
                      >
                          Close
                      </v-btn>
                      <v-btn
                          color="green"
                          text
                          @click="onSubmit"
                      >
                          Save
                      </v-btn>
                      </v-card-actions>
                  </v-card>
              </v-dialog>
          </v-col>
         
      </v-row>
      <!-- end add roles -->
      <v-spacer></v-spacer>
      <v-data-table
          :headers="headers"
          :items="userfile.content"
          :items-per-page="5"
          class="elevation-1"
          :search="search"
      >
          <template v-slot:top>
              <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
              ></v-text-field>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.displayName }}</td>
                <td>
                  <v-menu
                    origin="center left"
                    transition="scale-transition"
                    open-on-hover
                    top
                    offset-y
                  >
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            outlined
                            color="#0c2e4c"
                            dark
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                          >
                            <v-icon dark>
                              mdi-format-list-bulleted-square
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>More</span>
                      </v-tooltip>
                    </template>
                    <v-list dense>
                      <v-list-item-group
                      >
                        <v-list-item
                          v-for="(menuitem, index) in menuitems"
                          :key="index"
                         
                        >
                          <v-list-item-title @click="show(item.id)"><v-icon >{{ menuitem.icon }}</v-icon> {{ menuitem.title }}</v-list-item-title>
                      </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
      </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default{

  data(){
      return {
      displayName:'',
      roleName:'',
      PermUuid:'',
      dialog:false,
      search: '',
      headers: [
        { text: 'ID',value: 'id',},
        { text: 'Role Name', value: 'displayName' },
        { text: 'Action', value:''},
      ],
      menuitems: [
      { title: 'Edit',icon:'mdi-pencil-box',class:'edit' },
      { title: 'Delete' ,icon:'mdi-delete-forever',class:'delete'},
      ],
      }
  },
  computed:{
    ...mapGetters(['getRoles']),
    ...mapGetters(['getPermissions']),
      userfile()
      {
        return this.getRoles;
      },
      perm()
      {
        return this.getPermissions;
      },
  },
  mounted(){
    this.$store.dispatch('LoadRoles');
    this.$store.dispatch('LoadPermissions');
  },
  methods:{
  
      closeModal() {
          this.dialog = false;
      },
   
      onSubmit() {
          const PermissionDtoInput =[];
            this.PermUuid.forEach(o=>{
              const permissionarray = {permissionUuid:o}
              PermissionDtoInput.push(permissionarray);
            });
         
          const  role = {
            displayName: this.displayName,
            name: this.roleName,
            permissions:PermissionDtoInput
          }
          this.$store.dispatch('createUpdateRole', { role });
          this.dialog = false;
      },
  },

}

</script>