import getAllInstitutions from "../../GraphQL/Admin/Institutions/institutions.graphql";
import { displayErrorSuccessMessages } from "../../components/SharedComponent/Notification/notify";
import { apolloClient } from "@/vue-apollo";
import createUpdateInstitution from "../../GraphQL/Admin/Institutions/createUpdateInstitution.graphql"
import getUsersByInstitutionUuid from "../../GraphQL/Admin/Institutions/getUsersByInstitutionUuid.graphql"
export default {
    state: {
  
      getInstitutions: [],
      getInstitutionUsersList: [],
    },
  
    getters: {
      getInstitutions: (state) => state.getInstitutions,
      getInstitutionUsersList: (state) => state.getInstitutionUsersList,
    },
  
    mutations: {

      SetgetInstitutionUsersList(state, data){
        state.getInstitutionUsersList= [];
        state.getInstitutionUsersList=data;
      },

      // SetgetInstitutions(state, data) {
      //   state.getInstitutions = [];
      //   state.getInstitutions = data;
      // },

      loadInstitutions(state, data) {
        if (state.getInstitutions.find(({ uuid }) => uuid === data.uuid)) {
          state.getInstitutions.find(({ uuid }) => uuid === data.uuid).acronym = data.acronym;
          state.getInstitutions.find(({ uuid }) => uuid === data.uuid).email = data.email;
          state.getInstitutions.find(({ uuid }) => uuid === data.uuid).name = data.name;
          state.getInstitutions.find(({ uuid }) => uuid === data.uuid).phone = data.phone;
        } else {
          state.getInstitutions.push(data);
        }
      },
      clearInstitutions(state) {
        state.getInstitutions = [];
      },
      deleteInstitutions(state, data) {
        state.getInstitutions.splice(
          state.getInstitutions.findIndex(({ uuid }) => uuid === data),
          1
        );
      },


  
  
    },
  
    actions: {


      LoadInstitutions: async (context) => {
        context.commit("setLoadingState", false);
        await apolloClient
          .query({
            fetchPolicy: "network-only",
            query: getAllInstitutions,
            variables: {
            
            },
          })
          .then((output) => {
            console.log(output);

            output.data.getAllInstitutions.content?.forEach((element) => {
              let data={
                id:element.id,
                uuid:element.uuid,
                acronym:element.acronym,
                phone:element.phone,
                email:element.email,
                name:element.name,
              }
              context.commit("loadInstitutions", data);
            });
          }).finally(()=>{context.commit("setLoadingState", false);})
          .catch((error) => {
            context.commit("setLoadingState", false);
            const data = { 
              error: true,
              message:error 
            }
            displayErrorSuccessMessages(data);
            console.error("getInstitutions ==>", error);
          });
      },

    async createUpdateInstitutions(context,institution){
          apolloClient.mutate({
            mutation:createUpdateInstitution,
            variables:institution,
          })
          .then((response) => {
            let output =response.data.createUpdateInstitution;
            console.log(output);
            if(output.code == 5000){

              let tempdata={
                id:output.data.id,
                uuid:output.data.uuid,
                email:output.data.email,
                name:output.data.name,
                acronym:output.data.acronym,
                phone:output.data.phone,
               
              }
              context.commit("loadInstitutions",tempdata);
              context.commit("setLoadingState", false);
              const data = { 
                error: false,
                message:output.message 
              }
              displayErrorSuccessMessages(data);
            }
            const data = { 
              error: true,
              message:output.message 
            }
            displayErrorSuccessMessages(data);
          })
          .catch((error)=>{
                  console.error(error);
              });
        },

      LoadInstitutionUsersList: async (context,instuuid) => {
          context.commit("setLoadingState", true);
          await apolloClient
            .query({
              fetchPolicy: "network-only",
              query:getUsersByInstitutionUuid,
              variables: {
              uuid:instuuid
              },
            })
            .then((output) => {
              let data = output.data.getUsersByInstitutionUuid;
              console.log(data);
              context.commit("SetgetInstitutionUsersList",data);
              context.commit("setLoadingState", false);
            })
            .catch((error) => {
              context.commit("setLoadingState", false);
              const data = { 
                error: true,
                message:error 
              }
              displayErrorSuccessMessages(data);
             
              console.error("getInstitutionUsersList ==>", error);
            });
        },
  
  }
  
};