<template>
  <div>
    <v-btn
      outlined
      color="primary"
      rounded
      link
      @click="goBack"
      class="text-capitalize"
    >
      <v-icon>mdi-undo</v-icon>
      Back
    </v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
