<template>
    <v-app>
        <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Edit Institution</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Name*"
                      v-model="name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Acronym*"
                      v-model="acronym"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Email*"
                      v-model="email"
                      :rules="emailRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Phone*"
                      v-model="phone"
                      placeholder="255"
                      :rules="phonerules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                text
                @click="dialog=false"
              >
                Close
              </v-btn>
              <v-btn
                color="green"
                text
                @click="onSubmit"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <v-container fluid class="main-container">
          <div class="row ml-5 mt-5">
            <BackBtn />
          </div>
          &nbsp;
          <v-row>
            <v-col>
                <h2>
                    Institutions  
                </h2>
             </v-col>
            <v-col class="text-right">
                <Addinstitutions :dialog.sync="dialog"/>
            </v-col>
        </v-row>
        &nbsp;
        <hr>
                  &nbsp;
        <v-data-table
            :headers="headers"
            :items="institutions"
            :items-per-page="5"
            class="elevation-1"
            :search="search"
        >
            <template v-slot:top>
                <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
                ></v-text-field>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td>{{ item.id }}</td>
                  <!-- <td>{{ item.uuid }}</td> -->
                  <td>{{ item.name }}</td>
                  <td>{{ item.acronym }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.phone }}</td>
                  <td>
                    <v-menu
                      origin="center left"
                      transition="scale-transition"
                      open-on-hover
                      top
                      offset-y
                    >
                      <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                              outlined
                              color="#0c2e4c"
                              dark
                              v-bind="attrs"
                              v-on="{ ...tooltip, ...menu }"
                            >
                              <v-icon dark>
                                mdi-format-list-bulleted-square
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>More</span>
                        </v-tooltip>
                      </template>
                      <v-list dense>
                        <v-list-item-group
                        >
                          <v-list-item
                            v-for="(menuitem, index) in menuitems"
                            :key="index"
                          
                          >
                            <v-list-item-title @click="show(item)"><v-icon >{{ menuitem.icon }}</v-icon> {{ menuitem.title }}</v-list-item-title>
                        </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
        </v-data-table>
      </v-container>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import BackBtn from "../SharedComponent/Button/BackBtn.vue"
import Addinstitutions from "../Institutions/add-institution.vue"
export default{
  name:"Institutions",
    components:{
        BackBtn,
        Addinstitutions
    },
    data () {
      return {
        dialog:false,
        editdata:null,
        name:"",
        acronym:"",
        email:"",
        phone:"",
        uuid:"",
        search:'',
        emailRules: [
        value => !!value || 'Required.',    
            (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'E-mail must be valid'
        ],
        phonerules: [
            value => !!value || 'Required.',
            (value) => value && value.length >= 12 || 'Please put a valid number',
        ],
        headers: [
          { text: 'ID',value: 'id',},
          { text: 'Name', value: 'Name' },
          { text: 'Acronym', value: 'Acronym' },
          { text: 'Email', value: 'email' },
          { text: 'Phone', value: 'phone' },
          { text: 'Actions',value:'action' },
        ],
        menuitems: [
        { title: 'Edit',icon:'mdi-pencil-box',class:'edit' },
        { title: 'View',icon:'mdi-view-list',class:'view' },
        { title: 'Delete' ,icon:'mdi-delete-forever',class:'delete'},
        ],
      }
    },
    computed:{
        ...mapGetters(['getInstitutions']),
        institutions(){
            return this.getInstitutions
        }
    },
    mounted(){
        this.$store.dispatch('LoadInstitutions');
    },

    methods:{
      show(item){
        this.name=item.name;
        this.acronym=item.acronym;
        this.email=item.email;
        this.phone=item.phone;
        this.uuid=item.uuid;
        this.dialog=true;
        this.editdata=item;
        console.log(item)
      },
      onSubmit() {
        const  institution ={
            uuid:this.uuid,
            name:this.name,
            acronym:this.acronym,
            email:this.email,
            phone:this.phone,
        }
        this.$store.dispatch('createUpdateInstitutions', { institution });
        this.dialog = false;
      },
    },
}
</script>
<style>
.edit {
  color: rgb(0, 140, 255) !important;
}
.view {
  color:teal !important;
}
.delete {
  color:red  !important;
 
}
</style>