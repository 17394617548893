import getAllPermissions from "../../GraphQL/Admin/Permissions.graphql";
import { displayErrorSuccessMessages } from "../../components/SharedComponent/Notification/notify";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
  
      getPermissions: [],
    },
  
    getters: {
      getPermissions: (state) => state.getPermissions,
    },
  
    mutations: {
      SetgetPermissions(state, data) {
        state.getPermissions = [];
        state.getPermissions = data;
      },
  
  
    },
  
    actions: {
      
      LoadPermissions: async (context) => {
        context.commit("setLoadingState", false);
        await apolloClient
          .query({
            fetchPolicy: "network-only",
            query: getAllPermissions,
            variables: {
            
            },
          })
          .then((output) => {
            let data = output.data.getAllPermissions;
            context.commit("SetgetPermissions", data);
            context.commit("setLoadingState", false);
          })
          .catch((error) => {
            context.commit("setLoadingState", false);
            const data = { 
              error: true,
              message:error 
            }
            displayErrorSuccessMessages(data);
            console.error("getPermissions ==>", error);
          });
      },
  
  
  }
  
};