<template>
    <v-app>
        <v-container fluid class="main-container">
            <h2>
                Folders  Works
            </h2>
        </v-container>
    </v-app>
</template>


<script>

export default {
    name: "Folders",
}
</script>



<style></style>