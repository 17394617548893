import "regenerator-runtime/runtime";
import store from "./store";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { createProvider } from "./vue-apollo";
import contentEditable from "vue-contenteditable";
import PrintableDataTable from "printable-data-table";
import IdleVue from "idle-vue";
import VueTour from 'vue-tour';
import ScrollAnimation from '../src/components/SharedComponent/Animation/scrollanimation';
require('vue-tour/dist/vue-tour.css')

import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)

Vue.directive('scrollanimation', ScrollAnimation);
Vue.use(contentEditable);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
Vue.component(PrintableDataTable);
Vue.use(VueTour);


const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 900000, // 15 Minutes
  startAtIdle: false
});

Vue.filter("formatDate", function (date) {
  if (date !== "") {
    return new Date(date).toLocaleString();
  }
});

//  ****   PERMISSIONS   *** //

Vue.mixin({
  computed: {
    hasPermissions: () => (permission) => {
      const permissions = store.getters.getUserPermissions;
      
      let p = permissions.find(
        (_permission) => _permission?.name === permission
      );
      return p ? true : false;
    },


  },
});

new Vue({
  router,
  vuetify,
  store,
  PrintableDataTable,
  VueSweetalert2,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount("#app");
