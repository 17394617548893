// import getSignature from "../GraphQL/Documents/getSignature.graphql";
import getMyDocuments from "../GraphQL/Documents/getMyDocuments.graphql";
import createUpdateDocument from "../GraphQL/Documents/createUpdateDocuments.graphql"
import deleteDocument from "../GraphQL/Documents/deleteDocuments.graphql"
import createUpdateSignature from "../GraphQL/Documents/createUpdateSignature.graphql";
import { apolloClient } from "@/vue-apollo";
import { displayErrorSuccessMessages } from "../components/SharedComponent/Notification/notify.js";
import getMyInstitutionUsersList from "../GraphQL/Documents/getMyInstitutionUsersList.graphql"
import shareDocument from "../GraphQL/Documents/shareDocument.graphql"
import receivedDocument from "../GraphQL/Documents/receivedSharedDocument.graphql"
import signReceivedDocument from "../GraphQL/Documents/signedSharedDocuments.graphql"
import getSharedDoc from "../GraphQL/Documents/getSharedDocuments.graphql"
import getSharedDocumentDetails from "../GraphQL/Documents/getSharedDocumentDetails.graphql"
import getSignedDocument from "../GraphQL/Documents/getSignedDocument.graphql"
import getUnSignedDocument from "../GraphQL/Documents/getUnSignedDocument.graphql"
export const initializedPageableParameter = {
  pageNumber: 1,
  size: 10,
  sortBy: 'id',
  sortDirection: 'DESC',
};


export default {
  state: {
    getUnSignedRecievedDocument:[],
    getSignedRecievedDocument:[],
    getMyDocuments: [],
    getMyInstitutionUsersList: [],
    getReceivedSharedDocument:[],
    getSharedDocument:[],
    getSharedDocumentDetails:[],
    getCurrentUser:[],
  },

  getters: {
    getUnSignedRecievedDocument:(state) => state.getUnSignedRecievedDocument,
    getSignedRecievedDocument: (state) => state.getSignedRecievedDocument,
    getmyDocuments: (state) => state.getMyDocuments,
    getMyInstitutionUsersList: (state) => state.getMyInstitutionUsersList,
    getReceivedSharedDocument: (state) => state.getReceivedSharedDocument,
    getSharedDocument: (state) => state.getSharedDocument,
    getSharedDocumentDetails: (state) => state.getSharedDocumentDetails,
    getCurrentUser:(state)=> state.getCurrentUser,
  },

  mutations: {
    // DOCUMENTS
    loadDocuments(state, data) {
      if (state.getMyDocuments.find(({ uuid }) => uuid === data.uuid)) {
        state.getMyDocuments.find(({ uuid }) => uuid === data.uuid).title = data.title;
        state.getMyDocuments.find(({ uuid }) => uuid === data.uuid).description = data.description;
        state.getMyDocuments.find(({ uuid }) => uuid === data.uuid).filePath = data.filePath;
        state.getMyDocuments.find(({ uuid }) => uuid === data.uuid).signed = data.signed;
        state.getMyDocuments.find(({ uuid }) => uuid === data.uuid).createdAt = data.createdAt;
      
      } else {
        state.getMyDocuments.push(data);
      }
    },
    clearDocuments(state) {
      state.getMyDocuments = [];
    },
    deleteDocuments(state, data) {
      state.getMyDocuments.splice(
        state.getMyDocuments.findIndex(({ uuid }) => uuid === data),
        1
      );
    },
    // SetgetMyDocuments(state, data) {
    //   state.getMyDocuments = [];
    //   state.getMyDocuments = data;
    // },

    // 



    // INSTITUTIONS
    // loadMyInstitutionUsersList(state, data) {
    //   if (state.getMyInstitutionUsersList.find(({ uuid }) => uuid === data.uuid)) {
    //     state.getMyInstitutionUsersList.find(({ uuid }) => uuid === data.uuid).title = data.title;
    //     state.getMyInstitutionUsersList.find(({ uuid }) => uuid === data.uuid).description = data.description;
    //     state.getMyInstitutionUsersList.find(({ uuid }) => uuid === data.uuid).filePath = data.filePath;
    //   } else {
    //     state.getMyInstitutionUsersList.push(data);
    //   }
    // },
    // clearInstitutions(state) {
    //   state.getMyInstitutionUsersList = [];
    // },
    // deleteInstitutions(state, data) {
    //   state.getMyInstitutionUsersList.splice(
    //     state.getMyInstitutionUsersList.findIndex(({ uuid }) => uuid === data),
    //     1
    //   );
    // },

    // SetgetUnSignedRecievedDocument
    SetgetUnSignedRecievedDocument(state, data){
      state.getUnSignedRecievedDocument= [];
      state.getUnSignedRecievedDocument=data;
    },

    SetgetSignedRecievedDocument(state, data){
      state.getSignedRecievedDocument= [];
      state.getSignedRecievedDocument=data;
    },

    SetgetMyInstitutionUsersList(state, data){
      state.getMyInstitutionUsersList= [];
      state.getMyInstitutionUsersList=data;
    },

    SetgetReceivedSharedDocument(state, data){
      state.getReceivedSharedDocument= [];
      state.getReceivedSharedDocument=data;
    },

    SetgetSharedDocumentDetails(state, data){
      state.getSharedDocumentDetails= [];
      state.getSharedDocumentDetails=data;
    },

    SetgetSharedDocument(state, data){
      state.getSharedDocument= [];
      state.getSharedDocument=data;
    },

    SetgetShareDocument(state, data){
      state.getShareDocument= [];
      state.getShareDocument=data;
    },

    SetgetCurrentUser(state, data){
      state.getCurrentUser= [];
      state.getCurrentUser=data;
    }

  },

  actions: {

    LoadDocuments: async (context) => {
      context.commit("clearDocuments");
      context.commit("setLoadingState", true);
      await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getMyDocuments,
          variables: {
          
          },
        })
        .then((output) => {
          output.data.getMyDocuments.content?.forEach((element) => {
            let data={
              id:element.id,
              uuid:element.uuid,
              title:element.title,
              filePath:element.filePath,
              description:element.description,
              createdAt:element.createdAt,
              singed:element.signed,
            }
            context.commit("loadDocuments", data);
          });
        }).finally(()=>{context.commit("setLoadingState", false);})
        .catch((error) => {
          context.commit("setLoadingState", false);
          const data = { 
            error: true,
            message:error 
          }
          displayErrorSuccessMessages(data);
          console.error("getDocuments ==>", error);
        });
    },


    
   
    async saveUpdateDocuments(context,document){
      apolloClient.mutate({
        mutation:createUpdateDocument,
        variables:document,
      })
      .then((response) => {
        let output =response.data.createUpdateDocument;
        console.log(output.data);
        if(output.code == 5000){

          let tempdata={
            id:output.data.id,
            uuid:output.data.uuid,
            title:output.data.title,
            filePath:output.data.filePath,
            description:output.data.description,
            createdAt:output.data.createdAt,
            singed:output.data.signed,
          }

          context.commit("loadDocuments",tempdata);
          context.commit("setLoadingState", false);
          const data = { 
            error: true,
            message:output.message 
          }
          displayErrorSuccessMessages(data);
        }
        const data = { 
          error: true,
          message:output.message 
        }
        displayErrorSuccessMessages(data);
      })
      .catch((error)=>{
               console.error(error);
          });
    },

    // 

     async saveSharedSignedDocuments(context,document){
      context.commit("setLoadingState", true);
      apolloClient.mutate({
        mutation:signReceivedDocument,
        variables:document,
      })
      .then((response) => {
        let output =response.data.signReceivedDocument;
        console.log(output.data);
        if(output.code == 5000){

          context.commit("setLoadingState", false);
          const data = { 
            error: true,
            message:output.message 
          }
          displayErrorSuccessMessages(data);
        }
        const data = { 
          error: true,
          message:output.message 
        }
        displayErrorSuccessMessages(data);
        context.commit("setLoadingState", false);
      })
      .catch((error)=>{
               console.error(error);
          });
    },

    // 

   

    async deleteDocuments(context,uuid){
      apolloClient.mutate({
        mutation:deleteDocument,
        variables:uuid,
      })
      .then((response) => {
        let output =response.data.deleteDocument;
        console.log(output);
        if(output.code == 5000){
          
          context.commit("deleteDocuments", uuid);
          const data = { 
            error: true,
            message:output.message 
          }
          displayErrorSuccessMessages(data);
        }
        const data = { 
          error: true,
          message:output.message 
        }
        displayErrorSuccessMessages(data);
      })
      .catch((error)=>{
               console.error(error);
          });
    },


    async createUpdateSignature(context,SignatureDtoInput){
      // context.commit("setLoadingState", true);
      apolloClient.mutate({
        mutation:createUpdateSignature,
        variables:SignatureDtoInput,
      })
      .then((response) => {
        let output =response.data.createUpdateSignature;
        if(output.code == 5000){
          const data = { 
            error: true,
            message:output.message 
          }
          displayErrorSuccessMessages(data);
          context.commit("setLoadingState", false);
        }
        else {
          const data = { 
            error: false,
            message:output.message,
            code:output.code 
          }
          displayErrorSuccessMessages(data);
          context.commit("setLoadingState", false);
        }
      })
      .catch((error)=>{
               console.error(error);
          });
    },

    LoadMyInstitutionUsersList: async (context) => {
      context.commit("setLoadingState", true);
      await apolloClient
        .query({
          fetchPolicy: "network-only",
          query:getMyInstitutionUsersList,
          variables: {
          
          },
        })
        .then((output) => {
          let data = output.data.getMyInstitutionUsersList;
          console.log(data);
          context.commit("SetgetMyInstitutionUsersList", data);
          context.commit("setLoadingState", false);
        })
        .catch((error) => {
          context.commit("setLoadingState", false);
          const data = { 
            error: true,
            message:error 
          }
          displayErrorSuccessMessages(data);
         
          console.error("getMyInstitutionUsersList ==>", error);
        });
    },





    async ShareDocument(context,docDto){
      apolloClient.mutate({
        mutation:shareDocument,
        variables:{docDto},
      }).then((response) => {
        let output =response.data.shareDocument;
        if(output.code == 5000){
          const data = { 
            error: true,
            message:output.message 
          }
          displayErrorSuccessMessages(data);
        }
        else {
          const data = { 
            error: false,
            message:output.message,
            code:output.code 
          }
          displayErrorSuccessMessages(data);
        }
      })
      .catch((error)=>{
               console.error(error);
          });
    },

    LoadSharedDocument: async (context) =>{
      context.commit("setLoadingState", true);
      await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: receivedDocument,
          variables: {
          
          },
        })
        .then((output) => {
          let data = output.data.receivedDocument;
          context.commit("SetgetReceivedSharedDocument", data);
          context.commit("setLoadingState", false);
        })
        .catch((error) => {
          context.commit("setLoadingState", false);
          const data = { 
            error: true,
            message:error 
          }
          displayErrorSuccessMessages(data);
          // context.dispatch("displayErrorSuccessMessages", {
          //   error: true,
          //   message: error,
          // });
          console.error("getReceivedSharedDocument ==>", error);
        });
    },

    LoadSignedRecievedDocument: async (context) =>{
      context.commit("setLoadingState", true);
      await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getSignedDocument,
          variables: {
          
          },
        })
        .then((output) => {
          let data = output.data.getSignedDocument;
          context.commit("SetgetSignedRecievedDocument", data);
          context.commit("setLoadingState", false);
        })
        .catch((error) => {
          context.commit("setLoadingState", false);
          const data = { 
            error: true,
            message:error 
          }
          displayErrorSuccessMessages(data);

        });
    },

    LoadUnSignedRecievedDocument: async (context) =>{
      context.commit("setLoadingState", true);
      await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getUnSignedDocument,
          variables: {
          
          },
        })
        .then((output) => {
          let data = output.data.getUnSignedDocument;
          context.commit("SetgetUnSignedRecievedDocument", data);
          context.commit("setLoadingState", false);
        })
        .catch((error) => {
          context.commit("setLoadingState", false);
          const data = { 
            error: true,
            message:error 
          }
          displayErrorSuccessMessages(data);

        });
    },

    getSharedDocument: async (context) =>{
      context.commit("setLoadingState", true);
      await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getSharedDoc,
          variables: {
          
          },
        })
        .then((output) => {
          let data = output.data.getSharedDoc;
          console.log(data.content);
          const content=[];
          data.content.forEach( element=>{
            console.log(element);
            content.push(element)
          })
          context.commit("SetgetSharedDocument", content);
          context.commit("setLoadingState", false);
        })
        .catch((error) => {
          context.commit("setLoadingState", false);
          const data = { 
            error: true,
            message:error 
          }
          displayErrorSuccessMessages(data);
          // context.dispatch("displayErrorSuccessMessages", {
          //   error: true,
          //   message: error,
          // });
          console.error("getSharedDocument ==>", error);
        });
    },

    getSharedDocumentDetails:async (context,docuuid) =>{
      context.commit("setLoadingState", true);
      await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getSharedDocumentDetails,
          variables: {
          uuid:docuuid
          },
        })
        .then((output) => {
          let data = output.data.getSharedDocumentDetails.data;
          console.log(data.currentUser);
          const content=[];
          data.documentSignatureDetails.forEach( element=>{
            console.log(element);
            content.push(element)
          })
          context.commit("SetgetSharedDocumentDetails", content);
          context.commit("SetgetCurrentUser", data.currentUser);
          context.commit("setLoadingState", false);
        })
        .catch((error) => {
          context.commit("setLoadingState", false);
          const data = { 
            error: true,
            message:error 
          }
          displayErrorSuccessMessages(data);
          console.error("getSharedDocumentDetails ==>", error);
        });
    },

  

}

};
