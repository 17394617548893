<template>
    <v-row justify="center">
      <v-col class="text-right">
        <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="v-step-0"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              outlined
              rounded
            >
             <v-icon>mdi-file-document-plus-outline</v-icon><span>Add Document</span>
            </v-btn>
          </template>
          <v-card>
            <v-form validate-on="submit lazy" @submit.prevent="onSubmit">
            <v-card-title>
              <span class="text-h5">Add Document</span>
            </v-card-title>
            <v-card-text>
              
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Title*"
                      v-model="title"
                      :rules="rules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Description*"
                      v-model="description"
                      :rules="rules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <!-- <v-text>Choose File</v-text> -->
                    <input type="file" @change="onFileSelected" accept="pdf"  :rules="rules" style="width: 100%;">
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                text
                @click="closeModal"
              >
                Close
              </v-btn>
              <v-btn
                color="green"
                text
                @click="validate"
              >
                Save
              </v-btn>
            </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-col>
    
    </v-row>   
</template>


<script>

import { displayErrorSuccessMessages } from "../../components/SharedComponent/Notification/notify.js";

export default{

    name: "Documents",
   
    data(){
      return{
        title:'',
        description:'',
        dialog:false,
        rules: [
        value => {
          if (value) return true

          return 'Required.'
        },
      ],
      }
    },
    methods:{
      closeModal() {
        this.dialog = false;
      },
     
   //file upload
    onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    const parts = this.selectedFile.name.split(".");
          this.fileExtension = parts[parts.length - 1];
          if(this.fileExtension!= "pdf"){
            
            const data = { 
              error: false,
              message:"Please upload correct format" 
            }
            displayErrorSuccessMessages(data);
            this.$refs.file.value = null;
          }
    },
    validate(){
      if( this.title =="" && this.description == "" && this.selectedFile== null){
        const data = { 
            error: false,
            message:"Please fill the form" 
          }
          displayErrorSuccessMessages(data);
      }else{
        const parts = this.selectedFile.name.split(".");
        this.fileExtension = parts[parts.length - 1];
        if(this.fileExtension == "pdf"){
            this.onSubmit();
        }else{
          const data = { 
            error: false,
            message:"Please upload pdf" 
          }
          displayErrorSuccessMessages(data);
        }
        
      }
    },
    onSubmit() {
        const reader = new FileReader()
        reader.onload = () => {
          const parts = this.selectedFile.name.split(".");
          this.fileExtension = parts[parts.length - 1];
          const base64 = reader.result.split(",")[1]
          const document ={
            title:this.title,
            description:this.description,
            extension:"."+this.fileExtension,
            base64Content:base64
          }
          this.$store.dispatch('saveUpdateDocuments', { document });
          this.closeModal();
      }
      reader.readAsDataURL(this.selectedFile)
    },
    },

    components: { 
    },

    
    computed:{
     
    },
}  

</script>



<style>

</style>

