<template>
    <div>
        <v-row>
            <v-col class="ml-5">
                <BackBtn />
            </v-col>
        </v-row>
        &nbsp;
        <v-card>
          <template>
            <v-tabs
              v-model="tab"
              align-with-title
              background-color="primary"
              dark
            >
              <v-tab
              >
                Users
              </v-tab>
              <v-tab
              >
                Roles
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab">
                <v-tab-item
                >
                    <v-row>
                      <v-col class="text-right">
                          <v-btn
                            outlined
                            color="primary"
                            rounded
                            link
                            class="text-capitalize mt-4 ml-5"
                            v-bind="attrs"
                            v-on="on"
                            @click="openModal('Add')"
                          >
                              Add User
                          </v-btn>
                        </v-col>
                    </v-row>
                  
                    <v-data-table
                        :headers="userheaders"
                        :items="users"
                        :items-per-page="5"
                        class="elevation-1"
                    >
                        <template v-slot:top>
                            <v-text-field
                            v-model="search"
                            label="Search"
                            class="mx-4"
                            ></v-text-field>
                        </template>
                        <template  v-slot:item="row">
                            <tr>
                              <td>{{ row.index +1 }}</td>
                              <td>{{ row.item.firstName }}</td>
                              <td>{{ row.item.middleName }}</td>
                              <td>{{ row.item.lastName }}</td>
                              <td>{{ row.item.phone }}</td>
                              <td>{{ row.item.username }}</td>
                              <td>{{ row.item.institution?.name }}</td>
                              <td>{{ row.item.roles[0]?.name }}</td>
                              <td>
                                <v-menu
                                  origin="center left"
                                  transition="scale-transition"
                                  open-on-hover
                                  top
                                  offset-y
                                >
                                  <template v-slot:activator="{ on: menu, attrs }">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn
                                          outlined
                                          color="#0c2e4c"
                                          dark
                                          v-bind="attrs"
                                          v-on="{ ...tooltip, ...menu }"
                                        >
                                          <v-icon dark>
                                            mdi-format-list-bulleted-square
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>More</span>
                                    </v-tooltip>
                                  </template>
                                  <v-list dense>
                                    <v-list-item-group
                                    >
                                      <v-list-item
                                        
                                      >
                                        <v-list-item-title @click="openModal('Edit',row.item)"><v-icon >mdi-pencil-box</v-icon>Edit</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item
                                        
                                      >
                                        <v-list-item-title @click="deleteUser(row.item.uuid)"><v-icon >mdi-delete-forever</v-icon>Delete</v-list-item-title>
                                    </v-list-item>

                                    </v-list-item-group>
                                  </v-list>
                                </v-menu>
                              </td>
                            </tr>
                        </template>
                    </v-data-table>
                    <div>
                      <v-dialog
                        v-model="dialog"
                        persistent
                        max-width="300px"
                      >
                      <v-card>
                        <v-card-title>
                            <span class="text-h5">Delete User</span>
                        </v-card-title>
                      <v-card-text>
                          <v-container>
                              <v-row>
                                Are You Sure!
                              </v-row>
                          </v-container>
                      </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          text
                          @click="closeModal"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="green"
                          text
                          @click="onSubmit(uuid)"
                        >
                          Yes
                        </v-btn>
                    </v-card-actions>
                    </v-card>
                    </v-dialog>
                      <AddUsers v-if="showModal" :dialog="showModal" :data="data" :type="type" @close="closeModal" />
                    </div>
                </v-tab-item>
                <v-tab-item
                >
                <v-row>
                  <v-col class="text-right">
                      <v-dialog
                          v-model="dialog"
                          persistent
                          max-width="600px"
                      >
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  outlined
                                  color="primary"
                                  rounded
                                  link
                                  class="text-capitalize mt-4 ml-5"
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                  Add Role
                              </v-btn>
                          </template>
                          <v-card>
                              <v-card-title>
                              <span class="text-h5">Add Role</span>
                              </v-card-title>
                              <v-card-text>
                              <v-container>
                                  <v-row>
                                  <v-col cols="6">
                                      <v-text-field
                                      label="RoleName*"
                                      v-model="roleName"
                                      required
                                      ></v-text-field>
                                  </v-col>
                                  <v-col cols="6">
                                      <v-text-field
                                      label="DisplayName*"
                                      v-model="displayName"
                                      required
                                      ></v-text-field>
                                  </v-col>
                                  <v-col cols="6">
                                      <v-select
                                      :items="perm"
                                      item-text="name"
                                      item-value="uuid"
                                      label="Permission*"
                                      v-model="PermUuid"
                                      multiple
                                      persistent-hint
                                      required
                                      ></v-select>
                                  </v-col>
                                  </v-row>
                              </v-container>
                              <small>*indicates required field</small>
                              </v-card-text>
                              <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                  color="red"
                                  text
                                  @click="closeModal"
                              >
                                  Close
                              </v-btn>
                              <v-btn
                                  color="green"
                                  text
                                  @click="onSubmitRoles"
                              >
                                  Save
                              </v-btn>
                              </v-card-actions>
                          </v-card>
                      </v-dialog>
                  </v-col>
                
              </v-row>
              <!-- end add roles -->
              <v-spacer></v-spacer>
              <v-data-table
                  :headers="rolesheaders"
                  :items="roles"
                  :items-per-page="5"
                  class="elevation-1"
                  :search="search"
              >
                  <template v-slot:top>
                      <v-text-field
                      v-model="search"
                      label="Search"
                      class="mx-4"
                      ></v-text-field>
                  </template>
                  <template v-slot:item="row1">
                      <tr>
                        <td>{{ row1.index + 1 }}</td>
                        <td>{{ row1.item.displayName }}</td>
                        <td>
                          <v-menu
                            origin="center left"
                            transition="scale-transition"
                            open-on-hover
                            top
                            offset-y
                          >
                            <template v-slot:activator="{ on: menu, attrs }">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                  <v-btn
                                    outlined
                                    color="#0c2e4c"
                                    dark
                                    v-bind="attrs"
                                    v-on="{ ...tooltip, ...menu }"
                                  >
                                    <v-icon dark>
                                      mdi-format-list-bulleted-square
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>More</span>
                              </v-tooltip>
                            </template>
                            <v-list dense>
                              <v-list-item-group
                              >
                                <v-list-item  
                                >
                                  <v-list-item-title @click="deleteRole(row1.item.uuid)"><v-icon >mdi-delete-forever</v-icon>Delete</v-list-item-title>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                  </template>
              </v-data-table>
                </v-tab-item>
          </v-tabs-items>
        </v-card>

        <v-dialog
          v-model="dialog2"
          persistent
          max-width="300px"
        >
                      <v-card>
                        <v-card-title>
                            <span class="text-h5">Delete Role</span>
                        </v-card-title>
                      <v-card-text>
                          <v-container>
                              <v-row>
                                Are You Sure!
                              </v-row>
                          </v-container>
                      </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          text
                          @click="closeModal"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="green"
                          text
                          @click="onSubmit2(uuid)"
                        >
                          Yes
                        </v-btn>
                    </v-card-actions>
                    </v-card>
                    </v-dialog>

    </div>
</template>

<script>
import BackBtn from "../SharedComponent/Button/BackBtn.vue"
import AddUsers from '@/components/Admin/AddUsers.vue';
// import edituser from'@/components/Admin/editUser.vue';
import { mapGetters } from 'vuex';
// import { json } from "body-parser";
export default {
  name:"User Management",
    components:{
        BackBtn,
        AddUsers,
        // edituser
    },
    data () {
      return {
        uuid:null,
        dialog:false,
        dialog2:false,
        showModal:false,
        data:null,
        type:"",
        tab:null,
        PermUuid:"",
        roleName:'',
        displayName:'',
        search:'',
        userheaders: [
          { text: 'No',value: 'id'},
          // { text: 'UUID', value: 'uuid' },
          { text: 'First Name', value: 'firstName' },
          { text: 'Middle Name', value: 'middleName' },
          { text: 'Last Name', value: 'lastName' },
          { text: 'Phone', value: 'phone' },
          { text: 'Username', value: 'username' },
          { text: 'Institution', value: 'institution' },
          { text: 'Role', value: 'roles' },
          { text: 'Actions', value:'', sortable: false}
        ],
        rolesheaders: [
          { text: 'No',value: 'id',},
          { text: 'Role Name', value: 'displayName' },
          { text: 'Action', value:'',sortable: false},
        ],
        menuitems: [
        { title: 'Edit',icon:'mdi-pencil-box',class:'edit' },
        // { title: 'View',icon:'mdi-view-list',class:'view' },
        { title: 'Delete' ,icon:'mdi-delete-forever',class:'delete'},
        ],
      }
    },
    computed:{
      ...mapGetters(['getRoles']),
      ...mapGetters(['getPermissions']),
      ...mapGetters(['getUsers']),
        users()
        {
          
          return this.getUsers;
        },
        roles()
        {
          
          return this.getRoles;
        },
        perm()
        {
          return this.getPermissions;
        },
    },
    mounted(){
      this.$store.dispatch('LoadUsers');
      this.$store.dispatch('LoadRoles');
      this.$store.dispatch('LoadPermissions');
    },
    methods:{
      
      openModal(phase,data) {
        this.data=data;
        this.type=phase;
      this.showModal = true;
      },

      closeModal() {
        this.showModal = false;
        this.dialog=false;
        this.dialog2=false;
      },

      deleteUser(uuid){
        this.uuid=uuid;
        this.dialog=true;
        // this.$store.dispatch('DeleteUsers', uuid);
      },

      deleteRole(uuid){
        this.uuid=uuid;
        this.dialog2=true;
       
      },

      onSubmit(uuid){
        this.dialog=false;
        this.$store.dispatch('DeleteUsers', uuid);
      },

      onSubmit2(uuid){
        this.dialog2=false;
        this.$store.dispatch('DeleteRole', uuid);
      },

      onSubmitRoles() {
          const PermissionDtoInput =[];
            this.PermUuid.forEach(o=>{
              const permissionarray = {permissionUuid:o}
              PermissionDtoInput.push(permissionarray);
            });
         
          const  role = {
            displayName: this.displayName,
            name: this.roleName,
            permissions:PermissionDtoInput
          }
          this.$store.dispatch('createUpdateRole', { role });
          this.dialog = false;
      },

    }
   
  }
</script>
<style>
.edit {
  color: rgb(0, 140, 255) !important;
}
.view {
  color:teal !important;
}
.delete {
  color:red  !important;
 
}
</style>